import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./Components/Dashboard";
import Employee from "./Components/Employee";
import Login from "./Components/Login";
import Branch from "./Components/Branch";
import Purchaseorder from "./Components/Purchaseorder";
import Invoicestatus from "./Components/Invoicestatus";
import Paymentstatus from "./Components/Paymentstatus";
import Vendor from "./Components/Vendor";
import PageNotFound from "./PageNotFound";
import { useEffect } from "react";
import ApprovalMatrix from "./Components/ApprovalMatrix";
import AccessDenied from "./AccessDenied";
import SessionTimeout from "./Components/SessionTimeout";
import ViewPurchaseOrderDetails from "./Components/ViewPurchaseOrderDetails";
// import BarChartGraph from "./Components/BarChartGraph";

function App() {
  const [userId, setUserId] = useState("");
  const [userType, setUserType] = useState("");

  useEffect(() => {
    setUserId(sessionStorage.getItem("email"));
    setUserType(sessionStorage.getItem("loginType"));
  }, [sessionStorage.getItem("loginType")]);

  return (
    <>
      <SessionTimeout />

      <Routes>
        <Route exact path="/" element={<Login />} />
        {userType !== "" ? (
          <>
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/employee" element={<Employee />} />
            <Route exact path="/branch" element={<Branch />} />
            <Route exact path="/vendor" element={<Vendor />} />
            <Route exact path="/vendor/:vendorid" element={<Vendor />} />
            <Route exact path="/purchaseorder" element={<Purchaseorder />} />
            <Route
              exact
              path="/purchaseorder/:vendorid"
              element={<Purchaseorder />}
            />
            <Route
              exact
              path="/viewpurchaseorderdetails"
              element={<ViewPurchaseOrderDetails />}
            />
            <Route exact path="/invoicestatus" element={<Invoicestatus />} />
            <Route
              exact
              path="/invoicestatus/:id/:typeOfview"
              element={<Invoicestatus />}
            />
            <Route exact path="/approvalmatrix" element={<ApprovalMatrix />} />
            <Route exact path="/paymentstatus" element={<Paymentstatus />} />
            <Route
              exact
              path="/paymentstatus/:vendorid"
              element={<Paymentstatus />}
            />
            {/* <Route exact path="/barchartgraph" element={<BarChartGraph />} /> */}
          </>
        ) : (
          <Route exact path="*" element={<AccessDenied />} />
        )}
        <Route exact path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
