import React, { useState, useEffect } from "react";
import usercheck from "../images/897423.png";
import posent from "../images/897204.png";
import invoice from "../images/897428.png";
import paymentpaid from "../images/897429.png";
import poimg from "../images/po.png";
import invimg from "../images/inv.png";
import ppimg from "../images/pp.png";
import { Link } from "react-router-dom";
import "../css/dashboard.css";
import "../css/navbar.css";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { requestOptionsGet } from "./requestOptionHeaders";
import { baseURL } from "./BaseUrl";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import AccessDenied from "../AccessDenied";

function Dashboard() {
  const [dashboarddata, setDashboarddata] = useState([]);
  const [vendordata, setVendordata] = useState([]);
  const [recentPp, setRecentPP] = useState([]);
  const [recentInv, setRecentINV] = useState([]);
  const [recentPo, setRecentPO] = useState([]);
  const [recentActivityId, setRecentActivityId] = useState("");
  const [searchval, setSearchval] = useState("");

  const [isMenuOpen, setIsMenuOpen] = useState({
    style: "menu active",
    menuStatus: "open",
  });
  const [pagewrap, setPageWrap] = useState({
    style: "",
  });

  const userType = sessionStorage.getItem("loginType");
  const vendorCode = sessionStorage.getItem("login-code");

  const typeOfview = "vendorInv";

  useEffect(() => {
    fetchdashboarddata();
    recentActivities();
    fetchdashboardVendordata();
  }, [recentActivityId]);

  const fetchdashboarddata = () => {
    let requestOption = requestOptionsGet;
    if (userType === "vendor") {
      fetch(
        `${baseURL}/dashboard/get_data_widgets/vendors/?vendor_code=${vendorCode}`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          // console.log("RESPONSE:", res);
          setDashboarddata(res);
        });
    } else {
      fetch(`${baseURL}/dashboard/get_data_widgets`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          // console.log("RESPONSE:", res);
          setDashboarddata(res);
        });
    }
  };

  const fetchdashboardVendordata = () => {
    let requestOption = requestOptionsGet;
    if (userType === "vendor") {
      fetch(
        `${baseURL}/vendorjson/po_data/inv_data/pp_data/based_on_vendor/individual_vendor?pk_vendor_code=${vendorCode}`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          // console.log("DB-VENDOR-Login-RES:", res);
          setVendordata(res);
        });
    } else {
      fetch(
        `${baseURL}/vendorjson/po_data/inv_data/pp_data/based_on_vendors/multiple_vendors/`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          // console.log("DASH-VENDOR-RESPONSE:", res);
          setVendordata(res);
        });
    }
  };

  const recentActivities = () => {
    let requestOption = requestOptionsGet;
    fetch(
      `${baseURL}/purchase_orderjson/dashboard_get_data/recent_activity_po`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log("PO-RES:", res);
        if (recentActivityId == "") {
          setRecentPO(res.slice(0, 3));
        } else if (recentActivityId === "PO") {
          setRecentPO(res);
        } else {
          setRecentPO([]);
        }
      });
    fetch(
      `${baseURL}/invoice_statusjson/dashboard_get_data/recent_activity_invoice_status`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log("INV-RES:", res);
        if (recentActivityId == "") {
          setRecentINV(res.slice(0, 3));
        } else if (recentActivityId === "INV") {
          setRecentINV(res);
        } else {
          setRecentINV([]);
        }
      });
    fetch(
      `${baseURL}/payment_statusjson/dashboard_get_data/recent_activity_pp`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log("PP-RES:", res);
        if (recentActivityId == "") {
          setRecentPP(res.slice(0, 3));
        } else if (recentActivityId === "PP") {
          setRecentPP(res);
        } else {
          setRecentPP([]);
        }
      });
  };

  return (
    <div>
      {userType === "Admin" ||
      userType === "admin" ||
      userType === "Vendor" ||
      userType === "vendor" ||
      userType === "Approver" ||
      userType === "approver" ? (
        <div id="form1">
          <div className="leftbar-tab-menu">
            {/* TOP BAR START */}
            <Topbar
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
              pagewrap={pagewrap}
              setPageWrap={setPageWrap}
            />
            {/* TOP BAR END */}

            {/* NAV_BAR START */}
            <Navbar isMenuOpen={isMenuOpen.style} />
            {/* NAV_BAR END */}

            {/* SIDE BAR START */}
            <Sidebar />
            {/* SIDE BAR END */}
          </div>
          {/* DASHBOARD CONTENT START*/}
          <div className={`page-wrapper ${pagewrap.style}`}>
            <div className="page-content-tab">
              <div className="container-fluid dash-content">
                <div className="pagecontent">
                  <div className="row dash-summary">
                    <div className="col-md-12">
                      <div className="page-title-box">
                        <h4 className="dash-title">DASHBOARD</h4>
                      </div>
                    </div>
                  </div>
                  <div className="row card-data">
                    <div
                      className="col-md-3"
                      style={{
                        display:
                          userType === "vendor" || userType === "Vendor"
                            ? "none"
                            : "block",
                      }}
                    >
                      <div className="card act-card">
                        <div className="card-body">
                          <div className="row card-row">
                            <div className="col-md-3 actives">
                              <img
                                src={usercheck}
                                alt="user"
                                className="rounded user"
                              />
                            </div>
                            <div className="col-md-9 actnum">
                              <p className="act-body">
                                <b className="act-num">
                                  {dashboarddata?.active_vendors}
                                </b>
                                <br />
                                Active Vendors
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="card act-card">
                        <div className="card-body">
                          <div className="row card-row">
                            <div className="col-md-3 po">
                              <img
                                src={posent}
                                alt="user"
                                className="rounded sent"
                              />
                            </div>
                            <div className="col-md-9 actnum">
                              <p className="act-body">
                                <b className="act-num">
                                  {userType === "vendor" ||
                                  userType === "Vendor"
                                    ? dashboarddata?.po_recieved
                                    : dashboarddata?.po_sent}
                                </b>
                                <br />
                                {userType === "vendor" || userType === "Vendor"
                                  ? "PO Received"
                                  : "PO Created"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card act-card">
                        <div className="card-body">
                          <div className="row card-row">
                            <div className="col-md-3 invoice">
                              <img
                                src={invoice}
                                alt="user"
                                className="rounded received"
                              />
                            </div>
                            <div className="col-md-9 actnum">
                              <p className="act-body">
                                <b className="act-num">
                                  {userType === "vendor" ||
                                  userType === "Vendor"
                                    ? dashboarddata?.invoice_sent
                                    : dashboarddata?.invoice_received}
                                </b>
                                <br />
                                {userType === "vendor" || userType === "Vendor"
                                  ? "Invoice Received"
                                  : "Invoice Created"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card act-card">
                        <div className="card-body">
                          <div className="row card-row">
                            <div className="col-md-3 payment">
                              <img
                                src={paymentpaid}
                                alt="user"
                                className="rounded paid"
                              />
                            </div>
                            <div className="col-md-9 actnum">
                              <p className="act-body">
                                <b className="act-num">
                                  {dashboarddata?.payments_paid}
                                </b>
                                <br />
                                {userType === "vendor" || userType === "Vendor"
                                  ? "Payment Received"
                                  : "Payment Paid"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!--Row Summary--> */}
                  {userType === "vendor" || userType === "Vendor" ? (
                    <div className="row dash-card">
                      <div className="col-md-12">
                        <div className="vendor-bar-chart">
                          <h6 className="chart-heading">Vendor Graph Chart</h6>
                          <ResponsiveContainer width="90%" aspect={2}>
                            <BarChart
                              width={200}
                              height={350}
                              data={vendordata}
                              margin={{
                                top: 30,
                                right: 20,
                                left: 20,
                                bottom: 0,
                              }}
                              barSize={60}
                            >
                              <CartesianGrid strokeDasharray="1 2" />
                              <XAxis
                                dataKey="vendor_name"
                                // padding={{ left: 20, right: 20 }}
                              />
                              <YAxis
                                label={{
                                  value: "Count",
                                  angle: -90,
                                  position: "insideLeft",
                                }}
                              />
                              <Tooltip />
                              <Legend
                                verticalAlign="bottom"
                                wrapperStyle={{ lineHeight: "20px" }}
                              />
                              <Bar
                                dataKey="purchase_order"
                                fill="#ffda2a"
                                opacity={0.6}
                                name="Purchase Order"
                                label={{
                                  value: "PO",
                                  position: "inside",
                                }}
                                margin={{
                                  top: 0,
                                  right: 20,
                                  left: 0,
                                  bottom: 0,
                                }}
                              />
                              <Bar
                                dataKey="invoice_status"
                                fill="#ef4770"
                                opacity={0.6}
                                name="Invoice Status"
                                label={{
                                  value: "INV",
                                  position: "inside",
                                }}
                              />
                              <Bar
                                dataKey="payment_status"
                                fill="#00979e"
                                opacity={0.6}
                                name="Payment Status"
                                label={{
                                  value: "PP",
                                  position: "inside",
                                }}
                              />
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="row dash-card">
                      <div className="col-md-8">
                        <div className="card mytable">
                          <div className="card-body">
                            <div className="summary">
                              <div className="summary-title">
                                <h3 className="card-title">Summary</h3>
                              </div>
                              <div className="summary-search">
                                <span className="input-group">
                                  <input
                                    type="search"
                                    placeholder="Vendor Name / Vendor Code"
                                    aria-describedby="button-addon3"
                                    className="form-control search-dash-summary"
                                    onChange={(e) =>
                                      setSearchval(e.target.value)
                                    }
                                  />
                                  <i className="fa fa-search dash" />
                                </span>
                              </div>
                            </div>

                            <div className="row">
                              <div className="table-wrapper-scroll-x my-custom-scrollbar dash-scroll">
                                <table className="table dash-table">
                                  <thead className="dash-head">
                                    <tr className="data">
                                      <th className="dash-thead">
                                        Vendor Code
                                      </th>
                                      <th className="dash-thead">
                                        Vendor Name
                                      </th>
                                      <th className="dash-thead">PO</th>
                                      <th className="dash-thead">Invoice</th>
                                      <th className="dash-thead">
                                        Payments (₹)
                                      </th>
                                      <th className="dash-thead">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {vendordata !== "" ||
                                    vendordata !== null ? (
                                      vendordata
                                        .filter(
                                          (data) =>
                                            data?.vendor_name
                                              .toLowerCase()
                                              .includes(searchval) ||
                                            data?.pk_vendor_code.includes(
                                              searchval
                                            )
                                        )
                                        .map((vdata, i) => (
                                          <tr key={i}>
                                            <td className="dash-tbody">
                                              {vdata?.pk_vendor_code}
                                            </td>
                                            <td
                                              className="dash-tbody"
                                              style={{ maxWidth: "200px" }}
                                            >
                                              {vdata?.vendor_name}
                                            </td>
                                            <td
                                              className="dash-tbody"
                                              style={{ textAlign: "center" }}
                                            >
                                              <Link
                                                to={`/purchaseorder/${vdata?.pk_vendor_code}`}
                                                className="act-link"
                                              >
                                                {vdata?.purchase_order}
                                              </Link>
                                            </td>
                                            <td
                                              className="dash-tbody"
                                              style={{ textAlign: "center" }}
                                            >
                                              <Link
                                                to={`/invoicestatus/${vdata?.pk_vendor_code}/${typeOfview}`}
                                                className="act-link"
                                              >
                                                {vdata?.invoice_status}
                                              </Link>
                                            </td>
                                            <td
                                              className="dash-tbody"
                                              style={{ textAlign: "center" }}
                                            >
                                              <Link
                                                to={`/paymentstatus/${vdata?.pk_vendor_code}`}
                                                className="act-link"
                                              >
                                                {vdata?.payment_status}
                                              </Link>
                                            </td>
                                            <td className="dash-tbody">
                                              <Link
                                                to={`/vendor/${vdata?.pk_vendor_code}`}
                                                className="act-link"
                                              >
                                                View
                                              </Link>
                                            </td>
                                          </tr>
                                        ))
                                    ) : (
                                      <span className="no-data-found">
                                        ---- No Data Found ----
                                      </span>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 ">
                        <div className="card payment-card">
                          <div className="card-body">
                            <div className="row">
                              <div className="recent-activity-part">
                                <h3 className="dash-title">Recent Activity</h3>
                                <div className="dropdown">
                                  <button
                                    className="btn dropdown-recent-activity"
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <span className="dropdown-head">
                                      Select
                                    </span>
                                    <i className="bi bi-caret-down-fill" />
                                  </button>
                                  <ul
                                    className="dropdown-menu filtering-by-po-inv-pp"
                                    aria-labelledby="dropdownMenuButton1"
                                  >
                                    <li
                                      className="sort-recent-activity"
                                      id="ALL"
                                      onClick={() => setRecentActivityId("")}
                                    >
                                      <span className="dropdown-items accordion-body">
                                        All
                                        <img
                                          src={poimg}
                                          alt="all"
                                          className="img-fluid sort-ment"
                                          style={{ visibility: "hidden" }}
                                        />
                                      </span>
                                    </li>
                                    <li
                                      className="sort-recent-activity"
                                      id="PO"
                                      onClick={() => setRecentActivityId("PO")}
                                    >
                                      <span className="dropdown-items accordion-body">
                                        Purchase Orders
                                        <img
                                          src={poimg}
                                          alt="po"
                                          className="img-fluid sort-ment"
                                        />
                                      </span>
                                    </li>
                                    <li
                                      className="sort-recent-activity"
                                      id="INV"
                                      onClick={() => setRecentActivityId("INV")}
                                    >
                                      <span className="dropdown-items accordion-body">
                                        Invoice
                                        <img
                                          src={invimg}
                                          alt="po"
                                          className="img-fluid sort-ment"
                                        />
                                      </span>
                                    </li>
                                    <li
                                      className="sort-recent-activity"
                                      id="PP"
                                      onClick={() => setRecentActivityId("PP")}
                                    >
                                      <span className="dropdown-items accordion-body">
                                        Payments
                                        <img
                                          src={ppimg}
                                          alt="po"
                                          className="img-fluid sort-ment"
                                        />
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>

                            {/* RECENT ACTIVITY START */}

                            <div className="row mycard recent-act-card recent-scroll">
                              {recentPo !== ""
                                ? recentPo.map((data, i) => (
                                    <div
                                      className="card vendor"
                                      id="purchase-order"
                                      key={i}
                                    >
                                      <div className="card-body vendor-card">
                                        <div className="card-row-content">
                                          <span className="pay">
                                            <img
                                              src={poimg}
                                              alt="po"
                                              className="img-fluid "
                                            />
                                          </span>
                                          <span className="card-rtext">
                                            <p className="ven-text">
                                              Portea has raised PO &nbsp;
                                              <b>{data?.pk_po_DocEntry}</b>
                                              &nbsp; against &nbsp;
                                              <b className="icon-ven">
                                                {data?.vendor_name}
                                              </b>
                                              &nbsp; for a value of &nbsp;
                                              <b>Rs.{data?.doc_total}</b>
                                            </p>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                : ""}

                              {recentInv !== ""
                                ? recentInv.map((data, i) => (
                                    <div
                                      className="card vendor"
                                      id="invoice"
                                      key={i}
                                    >
                                      <div className="card-body vendor-card">
                                        <div className="card-row-content">
                                          <span className="pay">
                                            <img
                                              src={invimg}
                                              alt="inv"
                                              className="img-fluid"
                                            />
                                          </span>
                                          <span className="card-rtext">
                                            <p className="ven-text">
                                              <b className="icon-ven">
                                                {data?.vendor_name}
                                              </b>
                                              &nbsp; has created Invoice &nbsp;
                                              <b>{data?.pk_AP_DocEntry}</b>
                                              &nbsp; for a value of &nbsp;
                                              <b>Rs.{data?.payment_amount}</b>
                                              &nbsp; against the PO &nbsp;
                                              <b>{data?.fk_po_DocEntry}</b>
                                              &nbsp;raised by Portea
                                            </p>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                : ""}
                              {recentPp !== ""
                                ? recentPp.map((data, i) => (
                                    <div
                                      className="card vendor"
                                      id="payments"
                                      key={i}
                                    >
                                      <div className="card-body vendor-card">
                                        <div className="card-row-content">
                                          <span className="pay">
                                            <img
                                              src={ppimg}
                                              alt="pp"
                                              className="img-fluid ment"
                                            />
                                          </span>
                                          <span className="card-rtext">
                                            <p className="ven-text">
                                              Portea has made partial/full
                                              payment of &nbsp;
                                              <b>Rs.{data?.paid_amount}</b>
                                              &nbsp; against the Invoice Created
                                              by &nbsp;
                                              <b className="icon-ven">
                                                {data?.vendor_name}
                                              </b>
                                            </p>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                : ""}
                              {/* RECENT ACTIVITY END */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* <!--Row Summary--> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default Dashboard;
