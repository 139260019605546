import React, { useState, useEffect, useRef } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Topbar from "./Topbar";
import { Link, useParams } from "react-router-dom";
import excelimg from "../images/excel.png";
import pdfimg from "../images/pdf.png";
import "../css/approvalmatrix.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { requestOptionsGet } from "./requestOptionHeaders";
import { baseURL } from "./BaseUrl";
import { branchnamecode } from "./Branchnamecode";
import { Document, Page, Text, Image } from "react-pdf";
import { Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import "../css/pagination.css";
import AccessDenied from "../AccessDenied";
import ApprovalLineDetails from "./ApprovalLineDetails";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function ApprovalMatrix() {
  const [invoicedata, setInvoicedata] = useState([]);
  const [searchval, setSearchval] = useState("");
  const [status, setStatus] = useState({});
  const [branch, setBranch] = useState({});
  const [presentDate, setPresentDate] = useState("");
  const [approvalLineDetails, setApprovalLineDetails] = useState(false);
  const [approvalDocEntry, setApprovalDocEntry] = useState("");
  const currentDate = new Date();
  const oneMonthAgo = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 30
  );
  const [fromDate, setFromDate] = useState(oneMonthAgo);
  const [toDate, setToDate] = useState(new Date());

  const [isMenuOpen, setIsMenuOpen] = useState({
    style: "menu active",
    menuStatus: "open",
  });
  const [pagewrap, setPageWrap] = useState({
    style: "",
  });
  const [pdfdata, setPdfdata] = useState("");

  // PAGINATION START
  const PageSize = 20;
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);

  // console.log("PAGENO", currentPage);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  // VIEW PDF/IMG START

  const viewPDFLink = (e) => {
    window.open(e, "_blank");
  };

  // VIEW PDF/IMG END

  const handleCloseAprlLItems = () =>
    setApprovalLineDetails(!approvalLineDetails);

  useEffect(() => {
    fetchinvoicedata();
  }, [currentPage, PageSize, searchval, fromDate, toDate]);

  const userType = sessionStorage.getItem("loginType");
  if (userType === "Approver" || userType === "approver") {
    var empId = sessionStorage.getItem("login-code");
  }

  const fetchinvoicedata = () => {
    let requestOption = requestOptionsGet;
    fetch(
      `${baseURL}/invoice_savejson_matching_owners/for_approval_matrix_with_employee_code?start_date=${moment(
        fromDate
      ).format("YYYY-MM-DD")}&end_date=${moment(toDate).format(
        "YYYY-MM-DD"
      )}&emp_code=${empId}&search=${searchval}&page_num=${currentPage}&page_size=${PageSize}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log("APPROVAL-RESPONSE-VEN-EMP::", res);
        setInvoicedata(res?.data);
        setPageDataSize(res.data.length);
        setTotalemployee(res.total);
        setPageCount(Math.ceil(res.total / PageSize));
      })
      .catch((error) => console.error("ERROR:", error));
  };

  const serachBySelect = () => {
    fetchinvoicedata();
  };

  const viewComponent = (e) => {
    setApprovalLineDetails(!approvalLineDetails);
    setApprovalDocEntry(e);
  };

  const clearSerachBySelect = () => {
    setBranch({});
    setFromDate(oneMonthAgo);
    setToDate(new Date());
    setStatus({});
    fetchinvoicedata();
  };

  return (
    <div>
      {userType === "Admin" ||
      userType === "admin" ||
      userType === "approver" ||
      userType === "Approver" ? (
        <div id="form1">
          <div className="leftbar-tab-menu">
            {/* SIDE BAR START */}
            <Sidebar />
            {/* SIDE BAR END */}

            {/* NAV_BAR START */}
            <Navbar isMenuOpen={isMenuOpen.style} />
            {/* NAV_BAR END */}

            {/* TOP BAR START */}
            <Topbar
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
              pagewrap={pagewrap}
              setPageWrap={setPageWrap}
            />
            {/* TOP BAR END */}
          </div>

          {!approvalLineDetails ? (
            <div className={`page-wrapper ${pagewrap.style}`}>
              <div className="page-content-tab">
                <div className="container-fluid aprl-cont">
                  <div className="pagecontent">
                    <div className="row approval-summary">
                      <span className="page-table-title">APPROVAL MATRIX</span>

                      {/* SELECT FILTER BY VALUE START */}
                      <span className="seleting-menus">
                        <div className="approval-sea">
                          <div className="input-group approval-input">
                            <i className="fa fa-search" />
                            <input
                              type="search"
                              placeholder="PO DocEntry / Vendor Name"
                              aria-describedby="button-addon3"
                              className="form-control bg-none border-0 approval-input1"
                              onChange={(e) => setSearchval(e.target.value)}
                            />
                          </div>
                        </div>

                        <Form.Select
                          aria-label="Default select example"
                          name="branch"
                          className="form-control select"
                          value={branch}
                          onChange={(event) => setBranch(event.target.value)}
                        >
                          <option className="option-label">Branch</option>
                          {branchnamecode.map((br, i) => {
                            return (
                              <option key={i} value={br.code}>
                                {br.code}
                              </option>
                            );
                          })}
                        </Form.Select>

                        <span className="input-group from-to-date-picker d-flex">
                          <span
                            className="input-group-text select"
                            id="from-label"
                          >
                            From
                          </span>
                          <div>
                            <DatePicker
                              selected={fromDate}
                              // selectsStart
                              className="select from-date"
                              dateFormat="dd/MM/yyyy"
                              startDate={fromDate}
                              endDate={toDate}
                              onChange={(date) => setFromDate(date)}
                              maxDate={new Date()}
                            />
                          </div>
                          <span
                            className="input-group-text select"
                            id="to-label"
                          >
                            to
                          </span>
                          <div>
                            <DatePicker
                              selected={toDate}
                              // selectsEnd
                              className="select from-date"
                              dateFormat="dd/MM/yyyy"
                              startDate={fromDate}
                              endDate={toDate}
                              minDate={fromDate}
                              onChange={(date) => setToDate(date)}
                              maxDate={new Date()}
                            />
                          </div>
                        </span>

                        <button
                          type="button"
                          className="btn btn-select-search d-flex"
                          onClick={serachBySelect}
                        >
                          <span className="btn-inline-item d-flex">
                            <i className="bi bi-search"> </i>
                            <h6>Search</h6>
                          </span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-clear-select"
                          onClick={clearSerachBySelect}
                        >
                          <i className="bi bi-x-circle-fill" />
                        </button>
                      </span>
                      {/* SELECT FILTER BY VALUE END */}
                    </div>

                    <div className="row approval-status">
                      <div className="table-wrapper-scroll-x my-custom-scrollbar approval-scroll">
                        <table
                          className="table approval-table"
                          id="invstatus-table"
                        >
                          <thead className="approval-head">
                            <tr>
                              <th className="approval-thead">PO.DocEntry</th>
                              <th className="approval-thead">Vendor Ref.No</th>
                              <th className="approval-thead">PO Date</th>
                              {userType !== "vendor" ||
                              userType !== "Vendor" ? (
                                <th className="approval-thead">Vendor Name</th>
                              ) : (
                                ""
                              )}
                              {/* <th className="approval-thead">Branch</th> */}

                              <th
                                className="approval-thead"
                                style={{ textAlign: "center" }}
                              >
                                Attachment
                              </th>

                              {userType !== "vendor" ||
                              userType !== "Vendor" ? (
                                <th className="approval-thead">Action</th>
                              ) : (
                                ""
                              )}
                            </tr>
                          </thead>
                          <tbody className="approval-body">
                            {invoicedata !== "" || invoicedata !== null ? (
                              invoicedata
                                .filter(
                                  (inv) =>
                                    inv?._id?.includes(searchval) ||
                                    inv?.vendor_name
                                      ?.toLowerCase()
                                      .includes(searchval)
                                )
                                .map((item, i) => (
                                  <tr key={i}>
                                    <td className="approval-tbody">
                                      <span
                                        className="approval-tbody-act"
                                        onClick={() => viewComponent(item?._id)}
                                      >
                                        {item?._id}
                                      </span>
                                    </td>
                                    <td className="approval-tbody">
                                      {item?.vendor_ref_no}
                                    </td>
                                    <td className="approval-tbody">
                                      {moment(item?.po_date).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td className="approval-tbody">
                                      {item?.vendor_name}
                                    </td>

                                    {/* <td className="approval-tbody">
                                      {item?.fk_branch}
                                    </td> */}

                                    <td
                                      className="approval-tbody"
                                      style={{ textAlign: "center" }}
                                    >
                                      <i
                                        className="fa fa-paperclip"
                                        title="View Attachment"
                                        onClick={() =>
                                          viewPDFLink(item?.Attachment)
                                        }
                                      />
                                    </td>

                                    <td className="approval-tbody">
                                      <button
                                        className="btn-secondary porder-act"
                                        onClick={() => viewComponent(item?._id)}
                                      >
                                        <i className="bi bi-eye-fill" />
                                        View
                                      </button>
                                    </td>
                                  </tr>
                                ))
                            ) : (
                              <span className="no-data-found">
                                ---- No Data Found ----
                              </span>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="approval-down">
                    <span className="page-range">
                      <p>{pageDataFrom}</p>-<p>{pageDataTill}</p>
                      &nbsp; of &nbsp;
                      <p>{totalemployee}</p>
                    </span>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="Next >"
                      onPageChange={handlePageClick}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      pageCount={pageCount}
                      previousLabel="< Prev"
                      renderOnZeroPageCount={null}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      disabledClassName={"disabled"}
                    />
                    <button
                      type="button"
                      className="btn btn-primary approval-button"
                      // onClick={() => exportExcel(invoicedata)}
                    >
                      Download &nbsp;
                      <img src={excelimg} alt="excel" className="thumbnail" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <ApprovalLineDetails
              approvalLineDetails={approvalLineDetails}
              approvalDocEntry={approvalDocEntry}
              pagewrap={pagewrap}
              handleCloseAprlLItems={handleCloseAprlLItems}
            />
          )}
        </div>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default ApprovalMatrix;
