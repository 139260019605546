import React, { useState, useEffect, useRef } from "react";
import "../css/approvalmatrix.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import moment from "moment";
import "jspdf-autotable";
import { requestOptionsGet } from "./requestOptionHeaders";
import { baseURL, abaseURL } from "./BaseUrl";
import { Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import "../css/pagination.css";
import AccessDenied from "../AccessDenied";
import ApproveInvoiceModel from "./ApproveInvoiceModel";
import AlertMessageModel from "./AlertMessageModel";

function ApprovalLineDetails(props) {
  const [invoicedata, setInvoicedata] = useState([]);
  const [invoiceid, setInvoiceid] = useState(0);
  //   const [searchval, setSearchval] = useState("");
  const [status, setStatus] = useState({});
  const [branch, setBranch] = useState({});
  const [presentDate, setPresentDate] = useState("");
  const [aproveStatus, setApproveStatus] = useState(false);
  const [uId, setUid] = useState(null);
  const [invSaveId, setInvSaveId] = useState(null);
  const [vhdId, setVhdId] = useState("");
  const [approveRejectData, setApproveRejectData] = useState([]);
  const [modelShow, setModelShow] = useState(false);
  const [submitSuccessMessage, setSubmitSuccessMessage] = useState("");
  const [submitMessage, setSubmitMessage] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [responseStatus, setResponseStatus] = useState("");
  const [pdfdata, setPdfdata] = useState("");

  // PAGINATION START
  const PageSize = 10;
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  // console.log("PAGENO", currentPage);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  const handleClose = () => setApproveStatus(!aproveStatus);

  const handleAlertClose = () => setModelShow(false);

  const alertClosesuto = () => {
    setTimeout(() => setModelShow(false), 2000);
  };

  useEffect(() => {
    fetchinvoicedata();
  }, [aproveStatus]);

  const userType = sessionStorage.getItem("loginType");

  if (userType === "Approver" || userType === "approver") {
    var empId = sessionStorage.getItem("login-code");
  }

  const fetchinvoicedata = () => {
    let requestOption = requestOptionsGet;
    if (userType === "Approver" || userType === "approver") {
      fetch(
        `${baseURL}/invoice_savejson_matching_owners/from_vendor_table_invoice_from_emp/?emp_code=${empId}&fk_po_DocEntry=${props.approvalDocEntry}`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          console.log("APPROVAL-RESPONSE-VEN-EMP:----++++++", res);
          setInvoicedata(res);
          //   setPageDataSize(res.data.length);
          //   setTotalemployee(res.total);
          //   setPageCount(Math.ceil(res.total / PageSize));
        })
        .catch((error) => console.error("ERROR:", error));
    }
  };

  const [lineNumber, setLineNumber] = useState([]);
  const [itemId, setItemId] = useState("");
  let selectedItem = [];

  //   console.log("LINENUMEBER::", lineNumber);

  useEffect(() => {
    for (var i = 0; i < invoicedata.length; i++) {
      for (var k = 0; k < invoicedata[i].DocumentLines.length; k++) {
        for (var j = 0; j < lineNumber.length; j++) {
          if (
            invoicedata[i].invoice_save_id == lineNumber[j].invoiceId &&
            invoicedata[i].DocumentLines[k].id == lineNumber[j].docLineId &&
            lineNumber[j].lineStatus === "Approved" &&
            invoicedata[i].DocumentLines[k].fk_po_DocEntry ==
              props.approvalDocEntry
          ) {
            // console.log("invoicedata[i]", invoicedata[i]);
            selectedItem.push({
              ItemCode: invoicedata[i].DocumentLines[k].ItemCode,
              Quantity: invoicedata[i].DocumentLines[k].Qty,
              Price: invoicedata[i].DocumentLines[k].UnitRate,
              Currency: invoicedata[i].DocumentLines[k].Currency,
              DiscountPercent: invoicedata[i].DocumentLines[k].Discount,
              WarehouseCode: invoicedata[i].DocumentLines[k].fk_whs_code,
              UoMCode: invoicedata[i].DocumentLines[k].UOM,
              BaseEntry: invoicedata[i].DocumentLines[k].fk_po_DocEntry,
              BaseLine: invoicedata[i].DocumentLines[k].LineNum,
              BaseType: 22,
            });
          }
        }
      }
    }
  }, [lineNumber, invoicedata, selectedItem]);

  console.log("SAP-LINE_ITEMS", selectedItem);

  const setlineNumber = (e) => {
    if (e.target.checked) {
      setLineNumber([
        ...lineNumber,
        {
          invoiceId: e.target.value,
          docLineId: e.target.name,
          lineStatus: e.target.id,
        },
      ]);
    } else {
      setLineNumber(
        lineNumber.filter((value) => value.docLineId !== e.target.name)
      );
    }
    setItemId(e.target.name);
  };

  const sendMultipleLineItems = () => {
    let requestOption = requestOptionsGet;
    fetch(
      `${baseURL}/invoice_savejson_matching_owners/from_vendor_table_invoice_from_emp/?emp_code=${empId}&fk_po_DocEntry=${props.approvalDocEntry}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        let approveRejectData = res[0];
        // console.log("invoice responce", approveRejectData);

        const draftdata = {
          CardCode: approveRejectData.fk_vendor_code,
          DocDate: approveRejectData.po_date,
          DocDueDate: approveRejectData.po_DueDate,
          DocObjectCode: "oPurchaseInvoices",
          DocType: "dDocument_Items",
          ShipToCode: approveRejectData.shipto,
          PayToCode: approveRejectData.billto,
          BPL_IDAssignedToInvoice: JSON.stringify(approveRejectData.fk_branch),
          BaseEntry: approveRejectData.DocumentLines[0].fk_po_DocEntry,
          NumAtCard: approveRejectData.NumAtCard,
          U_IKVHDID: JSON.stringify(approveRejectData.invoice_save_id),
          Attachment: approveRejectData.Attachment,
          DocumentLines: selectedItem,
        };
        // console.log("SEND-PORTEA-DATA:", draftdata);

        let requestoptionPost = {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(draftdata),
        };

        fetch(`${abaseURL}/vendorapi/api/Portea/PostMsg`, requestoptionPost)
          .then((res) => res.json())
          .then((res) => {
            console.log("STATUS-INVOICE-SENT:", res);
            if (res.message === "Created") {
              setResponseStatus(res.status);
              setModelShow(!modelShow);
              setSubmitSuccessMessage(res.message);
              alertClosesuto();
            } else {
              setModelShow(!modelShow);
              setSubmitMessage(res.message);
              alertClosesuto();
            }
          });
      });
  };

  // const serachBySelect = () => {
  //   setInvoicedata(
  //     invoicedata.filter((inv) =>
  //       inv.fk_po_DocEntry.includes(props.approvalDocEntry)
  //     )
  //   );
  // };

  // const clearSerachBySelect = () => {
  //   setBranch({});
  //   setStatus({});
  //   fetchinvoicedata();
  // };

  return (
    <div>
      {props.approvalLineDetails ? (
        <div className={`page-wrapper ${props.pagewrap.style}`}>
          <div className="page-content-tab">
            <div className="container-fluid aprl-cont">
              <div className="pagecontent">
                <div className="row approval-summary">
                  <div className="col-md-12 main-heading">
                    <span className="back">
                      <button
                        id="go-back"
                        className="btn view-back"
                        onClick={props.handleCloseAprlLItems}
                      >
                        <i className="arrow left"></i>
                        <span>Back</span>
                      </button>
                    </span>

                    <span className="line">
                      <div className="paydetail-head">APPROVAL DETAILS</div>
                    </span>
                  </div>
                </div>

                <div className="row approval-status-line-items">
                  <div className="table-wrapper-scroll-x my-custom-scrollbar approval-scroll line-item">
                    <table
                      className="table approval-table"
                      id="invstatus-table"
                    >
                      <thead className="approval-head">
                        <tr>
                          <th className="approval-thead">PO.DocEntry</th>
                          {userType !== "vendor" || userType !== "Vendor" ? (
                            <th className="approval-thead">Vendor Name</th>
                          ) : (
                            ""
                          )}
                          {/* <th className="approval-thead">Branch</th> */}
                          {/* <th className="approval-thead">ItemCode</th> */}
                          <th className="approval-thead">Qty</th>
                          <th className="approval-thead">UnitRate</th>
                          <th className="approval-thead">UOM</th>
                          {/* <th className="approval-thead">Vendor Ref.No.</th> */}
                          <th className="approval-thead">Dept. Status</th>
                          {/* <th className="approval-thead">Fin. Status</th> */}
                          <th className="approval-thead">Remarks</th>
                          {userType !== "vendor" || userType !== "Vendor" ? (
                            <th className="approval-thead">Action</th>
                          ) : (
                            ""
                          )}
                        </tr>
                      </thead>
                      <tbody className="approval-body">
                        {invoicedata !== "" || invoicedata !== null ? (
                          invoicedata.map((item, i) =>
                            item.DocumentLines.map((data) => (
                              <tr key={i}>
                                <td className="approval-tbody">
                                  <span className="aplsta-act">
                                    <input
                                      type="checkbox"
                                      className="input-approve-checkbox"
                                      disabled={
                                        data.dept_status === "Approved"
                                          ? false
                                          : true
                                      }
                                      name={data?.id}
                                      id={data?.dept_status}
                                      value={item?.invoice_save_id}
                                      onClick={(e) => {
                                        {
                                          setlineNumber(e);
                                        }
                                      }}
                                    />
                                    {data?.fk_po_DocEntry}
                                  </span>
                                </td>
                                {userType !== "vendor" ||
                                userType !== "Vendor" ? (
                                  <td className="approval-tbody">
                                    {item?.vendor_name}
                                  </td>
                                ) : (
                                  ""
                                )}

                                <td className="approval-tbody">{data?.Qty}</td>
                                <td className="approval-tbody">
                                  {data?.UnitRate}
                                </td>
                                <td className="approval-tbody">{data?.UOM}</td>

                                {/* <td className="approval-tbody">
                                  {data?.NumAtCard}
                                </td> */}
                                <td
                                  className={
                                    data.dept_status === "Pending" ||
                                    data.dept_status === "pending"
                                      ? "approval-tbody pending"
                                      : data.dept_status === "Rejected"
                                      ? "approval-tbody rejected"
                                      : "approval-tbody approve"
                                  }
                                >
                                  {data?.dept_status}
                                </td>
                                {/* <td className="approval-tbody">
                                  {data?.inv_status}
                                </td> */}
                                <td className="approval-tbody">
                                  {data?.remarks}
                                </td>
                                {userType !== "vendor" ||
                                userType !== "Vendor" ? (
                                  <td className="approval-tbody">
                                    <button
                                      className={
                                        data?.dept_status === "Approved"
                                          ? "btn approve-status hide ok"
                                          : data?.dept_status === "Rejected"
                                          ? "btn approve-status hide cancel"
                                          : data?.dept_status === "Pending" ||
                                            data?.dept_status === "pending"
                                          ? "btn approve-status"
                                          : "btn approve-status"
                                      }
                                      onClick={() => {
                                        setApproveStatus(!aproveStatus);
                                        setVhdId(data?.fk_po_DocEntry);
                                        setUid(data?.id);
                                        setInvSaveId(item?.invoice_save_id);
                                      }}
                                    >
                                      <i className="bi bi-pencil-fill"></i>
                                      Update Status
                                    </button>
                                  </td>
                                ) : (
                                  ""
                                )}
                              </tr>
                            ))
                          )
                        ) : (
                          <span className="no-data-found">
                            ---- No Data Found ----
                          </span>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div>
                  <button
                    className="submit-invoice btn-select-search"
                    onClick={sendMultipleLineItems}
                  >
                    Submit Invoice
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <AccessDenied />
      )}

      <ApproveInvoiceModel
        aproveStatus={aproveStatus}
        handleClose={handleClose}
        vhdId={vhdId}
        uId={uId}
        invSaveId={invSaveId}
        fetchinvoicedata={fetchinvoicedata}
      />
      <AlertMessageModel
        modelShow={modelShow}
        alertMessage={submitMessage}
        submitSuccessMessage={submitSuccessMessage}
        responseStatus={responseStatus}
        handleAlertClose={handleAlertClose}
      />
    </div>
  );
}

export default ApprovalLineDetails;
