import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../css/viewpaymentdetails.css";
import moment from "moment";
import excelimg from "../images/excel.png";
import Paymentstatus from "./Paymentstatus";
import { requestOptionsGet } from "./requestOptionHeaders";
import { baseURL } from "./BaseUrl";

function ViewPaymentDetails(props) {
  const [paymentdetails, setPaymentdetails] = useState("");
  const [paymentlinedetails, setPaymentlinedetails] = useState([]);

  const paymentId = props.viewpaymentdata;

  useEffect(() => {
    fetchpaymentdetails();
    // fetchpaymentlineitems();
  }, [paymentId]);

  const fetchpaymentdetails = () => {
    let requestOption = requestOptionsGet;
    fetch(
      `${baseURL}/payment_statusjson/get_selected_item?pk_payment_num=${paymentId}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log("DATAVIEW:", res[0]);
        // console.log("PPITEMS:", res[0].PaymentLines);
        setPaymentdetails(res[0]);
        setPaymentlinedetails(res[0].PaymentLines);
      })
      .catch((error) => console.error("ERROR:", error));
  };

  // const fetchpaymentlineitems = () => {
  //   let requestOption = requestOptionsGet;
  //   fetch(
  //     `${baseURL}/payment_status_itemsjson/get_selected_item?fk_payment_num=${paymentId}`,
  //     requestOption
  //   )
  //     .then((res) => res.json())
  //     .then((res) => {
  //       console.log("PPITEMS:", res.data.PaymentLines);
  //     })
  //     .catch((error) => console.error("ERROR:", error));
  // };

  return (
    <div>
      {!props.viewdetails ? (
        <div className={`page-wrapper ${props.pagewrap.style}`}>
          <div className="page-content-tab">
            <div className="container-fluid paymentdetails-view">
              <div className="pagecontent">
                <div className="row view-summary">
                  <div className="col-md-12 main-heading">
                    <span className="back">
                      <button
                        id="go-back"
                        className="btn btn-secondary view-back"
                        onClick={props.goBacktopurchaseorder}
                      >
                        <i className="arrow left"></i>
                        <span>Back</span>
                      </button>
                    </span>

                    <span className="line">
                      <div className="paydetail-head">PAYMENT DETAILS</div>
                    </span>
                  </div>
                </div>
                <div className="row view-paydetails">
                  <div className="col-md-12">
                    <div className="card view-card-body">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row view1">
                              <div className="col-1"></div>
                              <div className="col-5">
                                <span className="view-head">
                                  PAYMENT NUMBER
                                </span>
                              </div>
                              <div className="col-6">
                                <span className="view-body">
                                  :&nbsp;&nbsp;&nbsp;&nbsp;
                                  {paymentdetails?.pk_payment_num}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6 right-details">
                            <div className="row view1">
                              <div className="col-1"></div>
                              <div className="col-5">
                                <span className="view-head">VENDOR NAME</span>
                              </div>
                              <div className="col-6">
                                <span className="view-body">
                                  :&nbsp;&nbsp;&nbsp;&nbsp;
                                  {paymentdetails?.vendor_name}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-md-6">
                            <div className="row view1">
                              <div className="col-1"></div>
                              <div className="col-5">
                                <span className="view-head">VENDOR CODE</span>
                              </div>
                              <div className="col-6">
                                <span className="view-body">
                                  :&nbsp;&nbsp;&nbsp;&nbsp;
                                  {paymentdetails?.fk_vendor_code}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 right-details">
                            <div className="row view1">
                              <div className="col-1"></div>
                              <div className="col-5">
                                <span className="view-head">
                                  VENDOR Ref.No.
                                </span>
                              </div>
                              <div className="col-6">
                                <span className="view-body">
                                  :&nbsp;&nbsp;&nbsp;&nbsp;
                                  {paymentdetails?.vendor_ref_no}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row view1">
                              <div className="col-1"></div>
                              <div className="col-5">
                                <span className="view-head">BRANCH</span>
                              </div>
                              <div className="col-6">
                                <span className="view-body">
                                  :&nbsp;&nbsp;&nbsp;&nbsp;
                                  {paymentdetails?.branch_name}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6 right-details">
                            <div className="row view1">
                              <div className="col-1"></div>
                              <div className="col-5">
                                <span className="view-head">
                                  PAYMENT AMOUNT
                                </span>
                              </div>
                              <div className="col-6">
                                <span className="view-body">
                                  :&nbsp;&nbsp;&nbsp;&nbsp;
                                  {paymentdetails?.payment_amount}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row view1">
                              <div className="col-1"></div>
                              <div className="col-5">
                                <span className="view-head">PAYMENT DATE</span>
                              </div>
                              <div className="col-6">
                                <span className="view-body">
                                  :&nbsp;&nbsp;&nbsp;&nbsp;
                                  {moment(paymentdetails?.payment_date).format(
                                    "YYYY/MM/DD"
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 right-details">
                            <div className="row view1">
                              <div className="col-1"></div>
                              <div className="col-5">
                                <span className="view-head">UTR NUMBER</span>
                              </div>
                              <div className="col-6">
                                <span className="view-body">
                                  :&nbsp;&nbsp;&nbsp;&nbsp;
                                  {paymentdetails?.payment_ref}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row viewpage">
                  <table className="table view-table my-custom-scrollbar viewpage-scroll">
                    <thead className="views-thead">
                      <tr>
                        <th className="view-thead">SI DocNum</th>
                        <th className="view-thead">Vendor Ref.No</th>
                        <th className="view-thead">AP DocNum</th>
                        <th className="view-thead">Invoice Amount</th>
                        {/* <th className="view-thead">TaxOnly</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {paymentlinedetails !== ""
                        ? paymentlinedetails.map((item, i) => (
                            <tr key={i}>
                              {/* <td className="view-tbody">{item.fk_AP_DocEntry}</td> */}
                              <td className="view-tbody">
                                {item?.fk_SI_DocNum}
                              </td>
                              <td className="view-tbody">
                                {item?.vendor_ref_no ?? "NA"}
                              </td>
                              <td className="view-tbody">{item?.AP_DocNum}</td>
                              <td className="view-tbody">{item?.inv_amount}</td>
                              {/* <td className="view-tbody">{item?.TaxOnly}</td> */}
                            </tr>
                          ))
                        : " NO DATA FOUND"}
                    </tbody>
                  </table>
                </div>
                <div className="invoice-save-btn">
                  <div className="payment-details-download d-flex">
                    <button
                      type="button"
                      className="btn btn-download-excel"
                      // onClick={(e) => exportExcel(paymentdata)}
                    >
                      Download &nbsp;
                      <img src={excelimg} alt="excel" className="thumbnail" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Paymentstatus />
      )}
    </div>
  );
}

export default ViewPaymentDetails;
