import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Topbar from "./Topbar";
import excelimg from "../images/excel.png";
import "../css/vendor.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Form } from "react-bootstrap";
import { branchnamecode } from "./Branchnamecode";
import AccessDenied from "../AccessDenied";
import ReactPaginate from "react-paginate";
import "../css/pagination.css";
import { requestOptionsGet } from "./requestOptionHeaders";
import { baseURL } from "./BaseUrl";

function Vendor() {
  const { vendorid } = useParams();
  const [vendordata, setVendordata] = useState([]);
  const [searchselect, setSearchselect] = useState([]);
  const [searchval, setSearchval] = useState("");
  const [branch, setBranch] = useState({});
  const [status, setStatus] = useState({});

  const [isMenuOpen, setIsMenuOpen] = useState({
    style: "menu active",
    menuStatus: "open",
  });
  const [pagewrap, setPageWrap] = useState({
    style: "",
  });

  // PAGINATION START
  const PageSize = 20;
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  // console.log("PAGENO", currentPage);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  // DOWNLOAD EXCEL-FORMATE START
  const fileName = "Vendor Details";
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const Heading = [
    {
      pk_vendor_code: "Vendor Code",
      vendor_name: "Vendor Name",
      mobile_no: "Mobile No",
      email: "Email",
      status: "Status",
      branch: "Branch",
    },
  ];

  const exportExcel = () => {
    const newVenData = vendordata.map((data) => {
      return {
        pk_vendor_code: data.pk_vendor_code,
        vendor_name: data.vendor_name,
        mobile_no: data.mobile_no,
        email: data.email,
        branch: data.branch[0],
        status: data.status,
      };
    });
    // console.log("VEN-EXCEL:", newVenData);

    const excelStyle = {};

    const ws = XLSX.utils.json_to_sheet(Heading, {
      header: [
        "pk_vendor_code",
        "vendor_name",
        "mobile_no",
        "email",
        "branch",
        "status",
      ],
      skipHeader: true,
      origin: 0,
    });
    XLSX.utils.sheet_add_json(ws, newVenData, {
      header: [
        "pk_vendor_code",
        "vendor_name",
        "mobile_no",
        "email",
        "branch",
        "status",
      ],
      skipHeader: true,
      origin: 1,
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  // DOWNLOAD EXCEL-FORMATE END

  useEffect(() => {
    fetchvendorhdata();
  }, [currentPage, PageSize, searchval]);

  const fetchvendorhdata = () => {
    let requestOption = requestOptionsGet;
    fetch(
      `${baseURL}/vendorjson/pages?search=${searchval}&page_num=${currentPage}&page_size=${PageSize}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log("RESPONSE:", res);
        setVendordata(res.data);
        setPageDataSize(res.data.length);
        setTotalemployee(res.total);
        setPageCount(Math.ceil(res.total / PageSize));
      })
      .catch((error) => console.error("ERROR:", error));
  };

  const serachBySelect = () => {
    fetchvendorhdata();
  };

  const clearSerachBySelect = () => {
    setBranch({});
    setStatus({});
    fetchvendorhdata();
  };

  const userType = sessionStorage.getItem("loginType");

  return (
    <div>
      {userType === "Admin" ||
      userType === "admin" ||
      userType === "approver" ||
      userType === "Approver" ? (
        <div id="form1">
          <div className="leftbar-tab-menu">
            {/* SIDE BAR START */}
            <Sidebar />
            {/* SIDE BAR END */}

            {/* NAV_BAR START */}
            <Navbar isMenuOpen={isMenuOpen.style} />
            {/* NAV_BAR END */}
          </div>

          {/* TOP BAR START */}
          <Topbar
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            pagewrap={pagewrap}
            setPageWrap={setPageWrap}
          />
          {/* TOP BAR END */}

          <div className={`page-wrapper ${pagewrap.style}`}>
            <div className="page-content-tab">
              <div className="container-fluid ven-cont">
                <div className="pagecontent">
                  <div className="row vendor-summary">
                    {/* SELECT FILTER BY VALUE START */}
                    <span className="page-table-title">VENDOR</span>
                    <span className="seleting-menus">
                      <div className="vendor-sea">
                        <div className="input-group mb-1 vender-input">
                          <i className="fa fa-search"></i>
                          <input
                            type="search"
                            placeholder=" Name / Code / Mobile / Email"
                            aria-describedby="button-addon3"
                            className="form-control bg-none border-0 vender-input3"
                            onChange={(e) => setSearchval(e.target.value)}
                          />
                        </div>
                      </div>
                      <Form.Select
                        aria-label="Default select example"
                        name="role"
                        className="form-control select"
                        value={branch}
                        onChange={(event) => setBranch(event.target.value)}
                      >
                        <option className="option-label">Branch</option>
                        {branchnamecode.map((br, i) => {
                          return (
                            <option key={i} value={br.name}>
                              {br.name}
                            </option>
                          );
                        })}
                      </Form.Select>

                      <Form.Select
                        aria-label="Default select example"
                        name="branch"
                        className="form-control select"
                        value={status}
                        onChange={(event) => setStatus(event.target.value)}
                      >
                        <option className="option-label">Status</option>
                        <option>Active</option>
                        <option>Inactive</option>
                      </Form.Select>

                      <button
                        type="button"
                        className="btn btn-select-search d-flex"
                        onClick={serachBySelect}
                      >
                        <span className="btn-inline-item d-flex">
                          <i className="bi bi-search"> </i>
                          <h6>Search</h6>
                        </span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-clear-select"
                        onClick={clearSerachBySelect}
                      >
                        <i className="bi bi-x-circle-fill"></i>
                      </button>
                    </span>
                    {/* SELECT FILTER BY VALUE END */}
                  </div>
                  <div className="row vender">
                    <div className="table-wrapper-scroll-x my-custom-scrollbar vendor-scroll">
                      <table
                        className="table vendor-table"
                        id="vendor-table-data"
                      >
                        <thead className="vendor-head">
                          <tr>
                            <th className="vendor-thead">Vendor Code</th>
                            <th className="vendor-thead">Vendor Name</th>
                            <th className="vendor-thead">R1</th>
                            <th className="vendor-thead">R2</th>
                            <th className="vendor-thead">R3</th>
                            <th className="vendor-thead">Contact Person</th>
                            <th className="vendor-thead">Mobile No</th>
                            {/* <th className="vendor-thead">Branch</th> */}
                            <th className="vendor-thead">Email</th>
                            <th className="vendor-thead">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {vendordata != "" ? (
                            vendordata.map((item, i) => (
                              <tr key={i}>
                                <td className="vendor-tbody">
                                  {item?.pk_vendor_code}
                                </td>
                                <td className="vendor-tbody">
                                  {item?.vendor_name}
                                </td>
                                <td className="vendor-tbody">
                                  {item?.pk_owner_1}
                                </td>
                                <td className="vendor-tbody">
                                  {item?.owner_2}
                                </td>
                                <td className="vendor-tbody">
                                  {item?.owner_3}
                                </td>
                                <td
                                  className="vendor-tbody"
                                  style={{ maxWidth: "100px" }}
                                >
                                  {item?.contact_name}
                                </td>
                                <td className="vendor-tbody">
                                  {item?.mobile_no}
                                </td>
                                {/* <td className="vendor-tbody">
                                    {item?.branch}
                                  </td> */}
                                <td className="vendor-tbody">{item?.email}</td>
                                {item?.status === "Active" ? (
                                  <td className="tab-body act">
                                    <span className="dot-ac"></span>
                                    {item?.status}
                                  </td>
                                ) : (
                                  <td className="tab-body ina">
                                    <span className="dot-in"></span>
                                    {item?.status}
                                  </td>
                                )}
                              </tr>
                            ))
                          ) : (
                            <span className="no-data-found">
                              ---- No Data Found ----
                            </span>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="vendor-down">
                  <span className="page-range">
                    <p>{pageDataFrom}</p>-<p>{pageDataTill}</p>
                    &nbsp; of &nbsp;
                    <p>{totalemployee}</p>
                  </span>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<i className="fa fa-angle-double-right" />}
                    onPageChange={handlePageClick}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={<i className="fa fa-angle-double-left" />}
                    renderOnZeroPageCount={null}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    disabledClassName={"disabled"}
                  />
                  <button
                    type="button"
                    className="btn btn-outline-primary float-right vender-button"
                    onClick={(e) => exportExcel(vendordata)}
                  >
                    Download &nbsp;
                    <img src={excelimg} alt="excel" className="thumbnail" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default Vendor;
