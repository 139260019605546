import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import timer from "../images/897193.png";

function Sidebar() {
  const [iconactive, setIconactive] = useState();
  const [metricaDashboard, setMetricaDashboard] = useState(true);
  // const [activestyle, setActivestyle] = useState("");
  // const [show, setshow] = useState(false);

  const makeIconactive = (e) => {
    setIconactive(e);
    setMetricaDashboard(true);
  };

  return (
    <div>
      <div className="main-icon-menu">
        <div className="main-icon-menu-body">
          <div className="position-reletive h-100" data-simplebar>
            <ul className="nav nav-tabs" role="tablist" id="tab-menu">
              <li
                className="nav-item img-icons"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="Dashboard"
                data-bs-trigger="hover"
              >
                <div
                  // to="#MetricaDashboard"
                  // to={`/dashboard/${metricaDashboard}`}
                  // to={<Navbar metricaDashboard={metricaDashboard} />}
                  id="dashboard-tab"
                  // onClick={(e) => makeIconactive(e.target.id)}
                  // className={`nav-link active ${
                  //   iconactive === "dashboard-tab" ? "active" : ""
                  // }`}
                  className="nav-link active"
                >
                  {/* <img src={timer} alt="dashboad" /> */}
                  <i className="bi bi-speedometer2" />
                </div>
              </li>
              <li
                className="nav-item img-icons"
                // data-bs-toggle="tooltip"
                // data-bs-placement="right"
                title="Masters"
                // data-bs-trigger="hover"
              >
                <div
                  // to="#MetricaDashboard"
                  // to={`/dashboard/${metricaDashboard}`}
                  // to={<Navbar metricaDashboard={metricaDashboard} />}
                  id="dashboard-tab"
                  // onClick={(e) => makeIconactive(e.target.id)}
                  // className={`nav-link active ${
                  //   iconactive === "dashboard-tab" ? "active" : ""
                  // }`}
                  className="nav-link active"
                >
                  <i className="bi bi-person-fill" />
                </div>
              </li>
              <li
                className="nav-item img-icons"
                // data-bs-toggle="tooltip"
                // data-bs-placement="right"
                title="Masters"
                // data-bs-trigger="hover"
              >
                <div
                  // to="#MetricaDashboard"
                  // to={`/dashboard/${metricaDashboard}`}
                  // to={<Navbar metricaDashboard={metricaDashboard} />}
                  id="dashboard-tab"
                  // onClick={(e) => makeIconactive(e.target.id)}
                  // className={`nav-link active ${
                  //   iconactive === "dashboard-tab" ? "active" : ""
                  // }`}
                  className="nav-link active"
                >
                  <i className="bi bi-building" />
                </div>
              </li>
              <li
                className="nav-item img-icons"
                // data-bs-toggle="tooltip"
                // data-bs-placement="right"
                title="Masters"
                // data-bs-trigger="hover"
              >
                <div
                  // to="#MetricaDashboard"
                  // to={`/dashboard/${metricaDashboard}`}
                  // to={<Navbar metricaDashboard={metricaDashboard} />}
                  id="dashboard-tab"
                  // onClick={(e) => makeIconactive(e.target.id)}
                  // className={`nav-link active ${
                  //   iconactive === "dashboard-tab" ? "active" : ""
                  // }`}
                  className="nav-link active"
                >
                  <i className="bi bi-people-fill" />
                </div>
              </li>
              <li
                className="nav-item img-icons"
                // data-bs-toggle="tooltip"
                // data-bs-placement="right"
                title="Masters"
                // data-bs-trigger="hover"
              >
                <div
                  // to="#MetricaDashboard"
                  // to={`/dashboard/${metricaDashboard}`}
                  // to={<Navbar metricaDashboard={metricaDashboard} />}
                  id="dashboard-tab"
                  // onClick={(e) => makeIconactive(e.target.id)}
                  // className={`nav-link active ${
                  //   iconactive === "dashboard-tab" ? "active" : ""
                  // }`}
                  className="nav-link active"
                >
                  <i className="bi bi-handbag-fill" />
                </div>
              </li>
              <li
                className="nav-item img-icons"
                // data-bs-toggle="tooltip"
                // data-bs-placement="right"
                title="Masters"
                // data-bs-trigger="hover"
              >
                <div
                  // to="#MetricaDashboard"
                  // to={`/dashboard/${metricaDashboard}`}
                  // to={<Navbar metricaDashboard={metricaDashboard} />}
                  id="dashboard-tab"
                  // onClick={(e) => makeIconactive(e.target.id)}
                  // className={`nav-link active ${
                  //   iconactive === "dashboard-tab" ? "active" : ""
                  // }`}
                  className="nav-link active"
                >
                  <i className="bi bi-receipt" />
                </div>
              </li>
              <li
                className="nav-item img-icons"
                // data-bs-toggle="tooltip"
                // data-bs-placement="right"
                title="Masters"
                // data-bs-trigger="hover"
              >
                <div
                  // to="#MetricaDashboard"
                  // to={`/dashboard/${metricaDashboard}`}
                  // to={<Navbar metricaDashboard={metricaDashboard} />}
                  id="dashboard-tab"
                  // onClick={(e) => makeIconactive(e.target.id)}
                  // className={`nav-link active ${
                  //   iconactive === "dashboard-tab" ? "active" : ""
                  // }`}
                  className="nav-link active"
                >
                  <i className="bi bi-credit-card" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
