import React, { useState, useEffect, useRef } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Topbar from "./Topbar";
import { Link, useParams } from "react-router-dom";
import excelimg from "../images/excel.png";
import pdfimg from "../images/pdf.png";
import "../css/invoicestatus.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { requestOptionsGet } from "./requestOptionHeaders";
import { baseURL } from "./BaseUrl";
import moment from "moment";
import { branchnamecode } from "./Branchnamecode";
import { Document, Page, Text, Image } from "react-pdf";
import { Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import "../css/pagination.css";
import AccessDenied from "../AccessDenied";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Invoicestatus() {
  const { id, typeOfview } = useParams();
  const [invoicedata, setInvoicedata] = useState([]);
  const [invoiceid, setInvoiceid] = useState(0);
  const [searchval, setSearchval] = useState("");
  const [status, setStatus] = useState({});
  const [branch, setBranch] = useState({});
  const [presentDate, setPresentDate] = useState("");
  const currentDate = new Date();
  const oneMonthAgo = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 30
  );
  const [fromDate, setFromDate] = useState(oneMonthAgo);
  const [toDate, setToDate] = useState(new Date());

  const [isMenuOpen, setIsMenuOpen] = useState({
    style: "menu active",
    menuStatus: "open",
  });
  const [pagewrap, setPageWrap] = useState({
    style: "",
  });
  const [pdfdata, setPdfdata] = useState("");

  // PAGINATION START
  const PageSize = 10;
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  // console.log("PAGENO", currentPage);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  // DOWNLOAD EXCEL-FORMATE START
  const fileName = "Invoicestatus-data";
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const Heading = [
    {
      pk_AP_DocEntry: "INV.DocEntry",
      AP_DocDate: "Doc.Date",
      AP_DocNum: "INV.DocNo",
      AP_DueDate: "Due.Date",
      fk_po_DocEntry: "PO.DocEntry",
      fk_branch: "Branch",
      vendor_name: "Vendor Name",
      payment_amount: "Payment(₹)",
      AP_status: "Status",
    },
  ];

  const exportExcel = () => {
    const newInvoiceData = invoicedata.map((data) => {
      return {
        pk_AP_DocEntry: data.pk_AP_DocEntry,
        AP_DocDate: moment(data.AP_DocDate).format("DD/MM/YYYY"),
        AP_DocNum: data.AP_DocNum,
        AP_DueDate: moment(data.AP_DueDate).format("DD/MM/YYYY"),
        fk_po_DocEntry: data.fk_po_DocEntry,
        fk_branch: data.fk_branch,
        vendor_name: data.vendor_name[0],
        payment_amount: data.payment_amount,
        AP_status: data.AP_status,
      };
    });

    const ws = XLSX.utils.json_to_sheet(Heading, {
      header: [
        "pk_AP_DocEntry",
        "AP_DocDate",
        "AP_DocNum",
        "AP_DueDate",
        "fk_po_DocEntry",
        "fk_branch",
        "vendor_name",
        "payment_amount",
        "AP_status",
      ],
      skipHeader: true,
      origin: 0,
    });
    XLSX.utils.sheet_add_json(ws, newInvoiceData, {
      header: [
        "pk_AP_DocEntry",
        "AP_DocDate",
        "AP_DocNum",
        "AP_DueDate",
        "fk_po_DocEntry",
        "fk_branch",
        "vendor_name",
        "payment_amount",
        "AP_status",
      ],
      skipHeader: true,
      origin: -1,
    });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  // DOWNLOAD EXCEL-FORMATE END

  // PDF EXPORT START
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Invoice Report";

    const headers = [
      [
        "INV-DOC No.",
        "Doc-Date",
        "PO-Doc-Entry",
        "Branch",
        "Payment ",
        "Due-Date",
      ],
    ];

    const data = [
      [
        pdfdata.AP_DocNum,
        moment(pdfdata.AP_DocDate).format("YYYY/MM/DD"),
        pdfdata.fk_po_DocEntry,
        pdfdata.fk_branch,
        pdfdata.payment_amount,
        moment(pdfdata.AP_DueDate).format("YYYY/MM/DD"),
      ],
    ];

    let content = {
      theme: "grid",
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.text("Invoice Data", 40, 120);
    doc.text(": 12345", 125, 120);
    doc.text("Invoice Data-1", 40, 140);
    doc.text(": APC1345", 135, 140);
    // doc.autoTable({ html: "#invstatus-table" });
    doc.save(`${pdfdata.AP_DocNum}.pdf`);
  };
  // PDF EXPORT END

  const userType = sessionStorage.getItem("loginType");
  if (userType === "Approver" || userType === "approver") {
    var empId = sessionStorage.getItem("login-code");
  } else if (userType === "vendor" || userType === "Vendor") {
    var vendorCode = sessionStorage.getItem("login-code");
  }

  useEffect(() => {
    fetchinvoicedata();
  }, [currentPage, PageSize, fromDate, toDate, searchval]);

  const fetchinvoicedata = () => {
    let requestOption = requestOptionsGet;
    if (userType === "vendor" || userType === "Vendor") {
      fetch(
        `${baseURL}/invoice_statusjson/get_selected_item/invoice_status_based_on_vendor_code/?start_date=${moment(
          fromDate
        ).format("YYYY-MM-DD")}&end_date=${moment(toDate).format(
          "YYYY-MM-DD"
        )}&fk_vendor_code=${vendorCode}&search=${searchval}`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          // console.log("INV-VEN-RES:::", res);
          setInvoicedata(res);
        })
        .catch((error) => console.error("ERROR:", error));
    } else if (typeOfview === "vendorInv") {
      fetch(
        `${baseURL}/invoice_statusjson/get_selected_item/invoice_status_based_on_vendor_code/?start_date=${moment(
          fromDate
        ).format("YYYY-MM-DD")}&end_date=${moment(toDate).format(
          "YYYY-MM-DD"
        )}&fk_vendor_code=${id}&search=${searchval}`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          // console.log("INV-VEN-RES:::", res);
          setInvoicedata(res);
        })
        .catch((error) => console.error("ERROR:", error));
    } else if (userType === "Approver" || userType === "approver") {
      fetch(
        `${baseURL}/invoice_statusjson_matching_owners/from_vendor_table/?start_date=${moment(
          fromDate
        ).format("YYYY-MM-DD")}&end_date=${moment(toDate).format(
          "YYYY-MM-DD"
        )}&emp_code=${empId}&search=${searchval}&page_num=${currentPage}&page_size=${PageSize}`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          // console.log("INV-VEN-RES:::", res);
          setInvoicedata(res.data);
          setPageDataSize(res.data.length);
          setTotalemployee(res.total);
          setPageCount(Math.ceil(res.total / PageSize));
        })
        .catch((error) => console.error("ERROR:", error));
    } else {
      fetch(
        `${baseURL}/invoice_statusjson/pages?start_date=${moment(
          fromDate
        ).format("YYYY-MM-DD")}&end_date=${moment(toDate).format(
          "YYYY-MM-DD"
        )}&search=${searchval}&page_num=${currentPage}&page_size=${PageSize}`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          // console.log("INVOICE-RESPONSE:", res);
          setInvoicedata(res.data);
          setPageDataSize(res.data.length);
          setTotalemployee(res.total);
          setPageCount(Math.ceil(res.total / PageSize));
        })
        .catch((error) => console.error("ERROR:", error));
    }
  };

  const serachBySelect = () => {
    fetchinvoicedata();
  };

  const clearSerachBySelect = () => {
    setBranch({});
    setFromDate(oneMonthAgo);
    setToDate(new Date());
    setStatus({});
    fetchinvoicedata();
  };

  return (
    <div>
      {userType === "Admin" ||
      userType === "admin" ||
      userType === "Vendor" ||
      userType === "vendor" ||
      userType === "approver" ||
      userType === "Approver" ? (
        <div id="form1">
          <div className="leftbar-tab-menu">
            {/* SIDE BAR START */}
            <Sidebar />
            {/* SIDE BAR END */}

            {/* NAV_BAR START */}
            <Navbar isMenuOpen={isMenuOpen.style} />
            {/* NAV_BAR END */}

            {/* TOP BAR START */}
            <Topbar
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
              pagewrap={pagewrap}
              setPageWrap={setPageWrap}
            />
            {/* TOP BAR END */}
          </div>

          {/* {typeOfview === "poDocNum" ? <Invoicepopup id={id} /> : ""} */}
          <div className={`page-wrapper ${pagewrap.style}`}>
            <div className="page-content-tab">
              <div className="container-fluid inv-cont">
                <div className="pagecontent">
                  <div className="row invoice-summary">
                    <span className="page-table-title">INVOICE STATUS</span>

                    {/* SELECT FILTER BY VALUE START */}
                    <span className="seleting-menus">
                      <div className="invoice-sea">
                        <div className="input-group invoice-input">
                          <i className="fa fa-search"></i>

                          <input
                            type="search"
                            placeholder="INV Doc.No. / Vendor Ref.No "
                            aria-describedby="button-addon3"
                            className="form-control bg-none border-0 invoice-input1"
                            onChange={(e) => setSearchval(e.target.value)}
                          />
                        </div>
                      </div>
                      <span className="input-group from-to-date-picker d-flex">
                        <span
                          className="input-group-text select"
                          id="from-label"
                        >
                          From
                        </span>
                        <div>
                          <DatePicker
                            selected={fromDate}
                            // selectsStart
                            className="select from-date"
                            dateFormat="dd/MM/yyyy"
                            startDate={fromDate}
                            endDate={toDate}
                            onChange={(date) => setFromDate(date)}
                            maxDate={new Date()}
                          />
                        </div>
                        <span className="input-group-text select" id="to-label">
                          to
                        </span>
                        <div>
                          <DatePicker
                            selected={toDate}
                            // selectsEnd
                            className="select from-date"
                            dateFormat="dd/MM/yyyy"
                            startDate={fromDate}
                            endDate={toDate}
                            minDate={fromDate}
                            onChange={(date) => setToDate(date)}
                            maxDate={new Date()}
                          />
                        </div>
                      </span>

                      <Form.Select
                        aria-label="Default select example"
                        name="branch"
                        className="form-control select"
                        value={branch}
                        onChange={(event) => setBranch(event.target.value)}
                      >
                        <option className="option-label">Branch</option>
                        {branchnamecode.map((br, i) => {
                          return (
                            <option key={i} value={br?.code}>
                              {br?.code}
                            </option>
                          );
                        })}
                      </Form.Select>

                      <Form.Select
                        aria-label="Default select example"
                        name="branch"
                        className="form-control select"
                        value={status}
                        onChange={(event) => setStatus(event.target.value)}
                      >
                        <option className="option-label">Status</option>
                        <option>O</option>
                        <option>C</option>
                      </Form.Select>

                      <button
                        type="button"
                        className="btn btn-select-search d-flex"
                        onClick={serachBySelect}
                      >
                        <span className="btn-inline-item d-flex">
                          <i className="bi bi-search"> </i>
                          <h6>Search</h6>
                        </span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-clear-select"
                        onClick={clearSerachBySelect}
                      >
                        <i className="bi bi-x-circle-fill"></i>
                      </button>
                    </span>
                    {/* SELECT FILTER BY VALUE END */}
                  </div>

                  <div className="row invoice-status">
                    <div className="table-wrapper-scroll-x my-custom-scrollbar istatus-scroll">
                      <table
                        className="table istatus-table"
                        id="invstatus-table"
                      >
                        <thead className="invoice-head">
                          <tr>
                            <th className="invoice-thead">INV.DocNo</th>
                            <th className="invoice-thead">Vendor Ref.No</th>
                            <th className="invoice-thead">Doc.Date</th>
                            <th className="invoice-thead">PO.DocEntry</th>
                            {userType === "vendor" || userType === "Vendor" ? (
                              ""
                            ) : (
                              <th className="invoice-thead">Vendor Name</th>
                            )}
                            {/* <th className="invoice-thead">Branch</th> */}
                            <th className="invoice-thead">Payment(&#8377;)</th>
                            {/* <th className="invoice-thead">Due.Date</th> */}
                            <th className="invoice-thead">Dept.Status</th>
                            <th className="invoice-thead">Fin.Status</th>
                            {/* <th className="invoice-thead">Action</th> */}
                          </tr>
                        </thead>
                        <tbody className="invoice-body">
                          {(userType !== "vendor" || userType !== "Vendor") &&
                          (invoicedata !== "" || invoicedata !== null) ? (
                            invoicedata.map((item, i) => (
                              <tr key={i}>
                                <td className="invsta-tbody">
                                  <span className="insta-act">
                                    {item?.SI_DocNum}
                                  </span>
                                </td>
                                <td className="invoice-tbody">
                                  {item?.vendor_ref_no}
                                </td>
                                <td className="invoice-tbody">
                                  {moment(item?.AP_DocDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </td>
                                <td className="invoice-tbody">
                                  {item?.fk_po_DocEntry}
                                </td>
                                {userType === "vendor" ||
                                userType === "Vendor" ? (
                                  ""
                                ) : (
                                  <td className="invoice-tbody">
                                    {item?.vendor_name}
                                  </td>
                                )}
                                {/* <td className="invoice-tbody">
                                    {item.fk_branch}
                                  </td> */}
                                <td className="invoice-tbody">
                                  {item?.payment_amount}
                                </td>
                                {/* <td className="invoice-tbody">
                                    {moment(item.AP_DueDate).format(
                                      "YYYY/MM/DD"
                                    )}
                                  </td> */}
                                {item?.dept_status === "Approved" ? (
                                  <td className="tab-body act">
                                    <span className="dot-sta-closed">
                                      {item?.dept_status}
                                    </span>
                                  </td>
                                ) : (
                                  <td className="tab-body act">
                                    <span className="dot-sta-open">
                                      {item?.dept_status}
                                    </span>
                                  </td>
                                )}
                                {item?.fin_status === "Approved" ? (
                                  <td className="tab-body act">
                                    <span className="dot-sta-closed">
                                      {item?.fin_status}
                                    </span>
                                  </td>
                                ) : (
                                  <td className="tab-body act">
                                    <span className="dot-sta-open">
                                      {item?.fin_status}
                                    </span>
                                  </td>
                                )}

                                {/* <td className="invsta-tbody">
                                    <img
                                      src={pdfimg}
                                      alt="pdf"
                                      id="dropdownMenuLink"
                                      data-bs-toggle="dropdown"
                                      // aria-expanded="false"
                                      className="rounded dropdown-toggle"
                                      onClick={() =>
                                        setInvoiceid(item.pk_AP_DocEntry)
                                      }
                                    />
                                    <ul
                                      className="dropdown-menu download-dropdown"
                                      aria-labelledby="dropdownMenuLink"
                                    >
                                      <li>
                                        <button
                                          id="pdfdownload"
                                          className="btn btn-outline-info float-end excel-down"
                                          onClick={() => exportPDF()}
                                        >
                                          Download &nbsp;
                                          <i className="fa fa-download"></i>
                                        </button>
                                      </li>
                                    </ul>
                                  </td> */}
                              </tr>
                            ))
                          ) : (
                            <>
                              {invoicedata !== "" || invoicedata !== null ? (
                                invoicedata
                                  .filter((inv) =>
                                    // inv?.SI_DocNum?.includes(searchval) ||
                                    inv?.fk_po_DocEntry.includes(searchval)
                                  )
                                  .map((item, i) => (
                                    <tr key={i}>
                                      <td className="invoice-tbody">
                                        {item?.pk_AP_DocEntry}
                                      </td>
                                      <td className="invsta-tbody">
                                        <span className="insta-act">
                                          {item?.SI_DocNum}
                                        </span>
                                      </td>
                                      <td className="invoice-tbody">
                                        {moment(item?.AP_DocDate).format(
                                          "YYYY/MM/DD"
                                        )}
                                      </td>
                                      <td className="invoice-tbody">
                                        {item?.fk_po_DocEntry}
                                      </td>
                                      {userType !== "vendor" ||
                                      userType !== "Vendor" ? (
                                        <td className="invoice-tbody">
                                          {item?.vendor_name}
                                        </td>
                                      ) : (
                                        ""
                                      )}

                                      {/* <td className="invoice-tbody">
                                        {item?.fk_branch}
                                      </td> */}
                                      <td className="invoice-tbody">
                                        {item?.payment_amount}
                                      </td>
                                      {/* <td className="invoice-tbody">
                                      {moment(item?.AP_DueDate).format(
                                        "YYYY/MM/DD"
                                      )}
                                    </td> */}

                                      {item?.dept_status === "Approved" ? (
                                        <td className="tab-body act">
                                          <span className="dot-sta-closed">
                                            {item?.dept_status}
                                          </span>
                                        </td>
                                      ) : (
                                        <td className="tab-body act">
                                          <span className="dot-sta-open">
                                            {item?.dept_status}
                                          </span>
                                        </td>
                                      )}
                                      {item?.fin_status === "Approved" ? (
                                        <td className="tab-body act">
                                          <span className="dot-sta-closed">
                                            {item?.fin_status}
                                          </span>
                                        </td>
                                      ) : (
                                        <td className="tab-body act">
                                          <span className="dot-sta-open">
                                            {item?.fin_status}
                                          </span>
                                        </td>
                                      )}

                                      {/* <td className="invsta-tbody">
                                      <img
                                        src={pdfimg}
                                        alt="pdf"
                                        id="dropdownMenuLink"
                                        data-bs-toggle="dropdown"
                                        // aria-expanded="false"
                                        className="rounded dropdown-toggle"
                                        onClick={() =>
                                          setInvoiceid(item?.pk_AP_DocEntry)
                                        }
                                      />
                                      <ul
                                        className="dropdown-menu download-dropdown"
                                        aria-labelledby="dropdownMenuLink"
                                      >
                                        <li>
                                          <button
                                            id="pdfdownload"
                                            className="btn btn-outline-info float-end excel-down"
                                            onClick={() => exportPDF()}
                                          >
                                            Download &nbsp;
                                            <i className="fa fa-download"></i>
                                          </button>
                                        </li>
                                      </ul>
                                    </td> */}
                                    </tr>
                                  ))
                              ) : (
                                <span className="no-data-found">
                                  ---- No Data Found ----
                                </span>
                              )}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="invoice-down">
                  <span className="page-range">
                    <p>{pageDataFrom}</p>-<p>{pageDataTill}</p>
                    &nbsp; of &nbsp;
                    <p>{totalemployee}</p>
                  </span>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<i className="fa fa-angle-double-right" />}
                    onPageChange={handlePageClick}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={<i className="fa fa-angle-double-left" />}
                    renderOnZeroPageCount={null}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    disabledClassName={"disabled"}
                  />
                  <button
                    type="button"
                    className="btn btn-primary invoice-button"
                    onClick={() => exportExcel(invoicedata)}
                  >
                    Download &nbsp;
                    <img src={excelimg} alt="excel" className="thumbnail" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default Invoicestatus;
