import React from "react";
import "../src/css/pagenotfound.css";

function AccessDenied() {
  return (
    <div>
      <div className="acces-denied-page">
        <h2 className="acces-denied-head">Error 403 - Access Denied</h2>
        <p> You dont have permission to view this site.</p>
      </div>
    </div>
  );
}

export default AccessDenied;
