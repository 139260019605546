import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Topbar from "./Topbar";
import excelimg from "../images/excel.png";
import "../css/employee.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx/xlsx.mjs";
import { branchnamecode } from "./Branchnamecode";
import { Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import "../css/pagination.css";
import AccessDenied from "../AccessDenied";
import Modal from "react-bootstrap/Modal";
import { requestOptionsGet } from "./requestOptionHeaders";
import { baseURL } from "./BaseUrl";

function Employee() {
  const [employee, setEmployee] = useState([]);
  const [rolesDropdown, setRolesDropdown] = useState([]);
  const [departmentDropdown, setDepartmentDropdown] = useState([]);
  const [branchDropdown, setBranchDropdown] = useState([]);
  const [statusDropdown, setStatusDropdown] = useState([]);
  const [role, setRole] = useState("");
  const [department, setDepartment] = useState("");
  const [branch, setBranch] = useState("");
  const [status, setStatus] = useState("");
  const [searchval, setSearchval] = useState("");
  const [alertModelShow, setAlertModelShow] = useState(false);
  const [empCode, setEmpCode] = useState("");
  const [deleteSuccessRes, setDeleteSuccessRes] = useState("");

  const [isMenuOpen, setIsMenuOpen] = useState({
    style: "menu active",
    menuStatus: "open",
  });
  const [pagewrap, setPageWrap] = useState({
    style: "",
  });

  // PAGINATION START
  const PageSize = 20;
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  // console.log("PAGENO", currentPage);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const handleAlertClose = () => {
    setAlertModelShow(!alertModelShow);
    setEmpCode("");
    setDeleteSuccessRes("");
  };

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  // DOWNLOAD EXCEL-FORMATE START
  const fileName = "Employee Data";
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const Heading = [
    {
      pk_emp_code: "Employee Code",
      emp_name: "Employee",
      role: "Role",
      dept: "Department",
      mobile_no: "Mobile No",
      email: "Email",
      branch_name: "Branch Name",
    },
  ];

  const exportExcel = () => {
    const newEmpData = employee.map((data) => {
      return {
        pk_emp_code: data.pk_emp_code,
        emp_name: data.emp_name,
        role: data.role,
        dept: data.dept,
        mobile_no: data.mobile_no,
        email: data.email,
        branch_name: data.branch_name[0],
      };
    });

    const ws = XLSX.utils.json_to_sheet(Heading, {
      header: [
        "pk_emp_code",
        "emp_name",
        "role",
        "dept",
        "mobile_no",
        "email",
        "branch_name",
      ],
      skipHeader: true,
      origin: 0,
    });
    XLSX.utils.sheet_add_json(ws, newEmpData, {
      header: [
        "pk_emp_code",
        "emp_name",
        "role",
        "dept",
        "mobile_no",
        "email",
        "branch_name",
      ],
      skipHeader: true,
      origin: -1,
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  // DOWNLOAD EXCEL-FORMATE END

  useEffect(() => {
    fetchemployeedata();
  }, [currentPage, PageSize, role, department, branch, status, searchval]);

  const fetchemployeedata = () => {
    let requestOption = requestOptionsGet;
    fetch(
      `${baseURL}/employee_masterjson/Employee/filter_with_pagination/?role=${role}&dept=${department}&fk_branch=${branch}&status=${status}&name=${searchval}&page_num=${currentPage}&page_size=${PageSize}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log("EMP-DATA:", res);
        setEmployee(res.data);
        setPageDataSize(res.data.length);
        setTotalemployee(res.total);
        setPageCount(Math.ceil(res.total / PageSize));
      })
      .catch((error) => console.error("ERROR:", error));
  };


  useEffect(() => {
    fetchDropdowndata();
  }, []);

  const fetchDropdowndata = () => {
    let requestOption = requestOptionsGet;
    fetch(`${baseURL}/employee_masterjson/get_distinct_role`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        // console.log("ROLE-RES:", res);
        setRolesDropdown(res);
      })
      .catch((error) => console.error("ERROR:", error));
    fetch(`${baseURL}/employee_masterjson/get_distinct_dept`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        // console.log("DEPT-RES:", res);
        setDepartmentDropdown(res);
      })
      .catch((error) => console.error("ERROR:", error));
    fetch(`${baseURL}/employee_masterjson/get_distinct_status`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        // console.log("STATUS-RES:", res);
        setStatusDropdown(res);
      })
      .catch((error) => console.error("ERROR:", error));
  };

  const deleteEmployee = (employeeCode) => {
    let requestOption = requestOptionsGet;
    fetch(
      `${baseURL}/employee_masterjson/employee_master/delete_records?pk_emp_code=${employeeCode}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log("RESPONSE:", res);
        if (res.status == 100) {
          setDeleteSuccessRes(res.message);
        } else {
          setDeleteSuccessRes(res.message);
        }
        fetchemployeedata();
      })
      .catch((error) => console.error("ERROR:", error));
  };

  const clearSerachBySelect = () => {
    setBranch("");
    setRole("");
    setDepartment("");
    setStatus("");
    fetchemployeedata();
  };

  const userType = sessionStorage.getItem("loginType");

  return (
    <div>
      {userType === "Admin" || userType === "admin" ? (
        <div id="form1">
          <div className="leftbar-tab-menu">
            {/* SIDE BAR START */}
            <Sidebar />
            {/* SIDE BAR END */}

            {/* NAV_BAR START */}
            <Navbar isMenuOpen={isMenuOpen.style} />
            {/* NAV_BAR END */}
            {/* TOP BAR START */}
            <Topbar
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
              pagewrap={pagewrap}
              setPageWrap={setPageWrap}
            />
            {/* TOP BAR END */}
          </div>

          <div className={`page-wrapper ${pagewrap.style}`}>
            <div className="page-content-tab">
              <div className="container-fluid emp-content">
                <div className="pagecontent">
                  <div className="row employee-summary">
                    {/* <div className="page-title-box"> */}
                    {/* <h3 className="employee-title">Employee Master</h3> */}
                    {/* </div> */}

                    {/* SELECT FILTER BY VALUE START */}
                    <span className="seleting-menus">
                      <span className="page-table-title">EMPLOYEE MASTER</span>
                      <Form.Select
                        aria-label="Default select example"
                        name="role"
                        className="form-control select"
                        value={role}
                        onChange={(event) => {
                          setRole(event.target.value);
                        }}
                      >
                        <option>Role</option>
                        {rolesDropdown.map((data, i) => (
                          <option className="option-label" key={i}>
                            {data}
                          </option>
                        ))}
                      </Form.Select>

                      <Form.Select
                        aria-label="Default select example"
                        name="department"
                        className="form-control select"
                        value={department}
                        onChange={(event) => {
                          setDepartment(event.target.value);
                        }}
                      >
                        <option className="option-label">Department</option>
                        {departmentDropdown.map((data, i) => (
                          <option key={i}>{data}</option>
                        ))}
                      </Form.Select>

                      <Form.Select
                        aria-label="Default select example"
                        name="branch"
                        className="form-control select"
                        value={branch}
                        onChange={(event) => {
                          setBranch(event.target.value);
                        }}
                      >
                        <option className="option-label">Branch</option>
                        {branchnamecode.map((data, i) => (
                          <option key={i} value={data.code}>
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>

                      <Form.Select
                        aria-label="Default select example"
                        name="status"
                        className="form-control select"
                        value={status}
                        onChange={(event) => {
                          setStatus(event.target.value);
                        }}
                      >
                        <option className="option-label">Status</option>
                        {statusDropdown.map((data, i) => (
                          <option key={i}>{data}</option>
                        ))}
                      </Form.Select>

                      <button
                        type="button"
                        className="btn btn-select-search d-flex"
                        onClick={fetchemployeedata}
                      >
                        <span className="btn-inline-item d-flex">
                          <i className="bi bi-search"> </i>
                          <h6>Search</h6>
                        </span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-clear-select"
                        onClick={clearSerachBySelect}
                      >
                        <i className="bi bi-x-circle-fill"></i>
                      </button>
                    </span>
                    {/* SELECT FILTER BY VALUE END */}
                    <div className="employee-sea">
                      <div className="input-group mb-1 emp-input">
                        <i className="fa fa-search"></i>
                        <input
                          type="search"
                          placeholder="Employee Name / Mobile Number"
                          aria-describedby="button-addon3"
                          className="form-control bg-none border-0 employee-input"
                          onChange={(e) => setSearchval(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row employee">
                    <div className="table-wrapper-scroll-x my-custom-scrollbar employee-scroll">
                      <table className="table employee-table">
                        <thead className="employee-head">
                          <tr>
                            <th className="tab-head">Employee Code</th>
                            <th className="tab-head">Employee</th>
                            <th className="tab-head">Role</th>
                            <th className="tab-head">Department</th>
                            <th className="tab-head">Mobile No</th>
                            <th className="tab-head">Branch</th>
                            <th className="tab-head">Email</th>
                            <th className="tab-head">Status </th>
                            <th className="tab-head">Action </th>
                          </tr>
                        </thead>
                        <tbody className="employee-body">
                          {employee !== "" && employee !== null ? (
                            employee.map((item, i) => (
                              <tr key={i}>
                                <td className="tab-body">
                                  {item?.pk_emp_code}
                                </td>
                                <td className="tab-body">{item?.emp_name}</td>
                                <td className="tab-body">{item?.role}</td>
                                <td className="tab-body">{item?.dept}</td>
                                <td className="tab-body">{item?.mobile_no}</td>
                                <td className="tab-body">
                                  {item?.branch_name}
                                </td>
                                <td
                                  className="tab-body"
                                  style={{ maxWidth: "200px" }}
                                >
                                  {item?.email}
                                </td>
                                {item?.status === "Active" ? (
                                  <td className="tab-body act">
                                    <span className="dot-ac"></span>
                                    {item?.status}
                                  </td>
                                ) : (
                                  <td className="tab-body ina">
                                    <span className="dot-in"></span>
                                    {item?.status}
                                  </td>
                                )}
                                <td className="tab-body">
                                  <button
                                    className="btn-delete"
                                    title="Delete"
                                    onClick={() => {
                                      setAlertModelShow(!alertModelShow);
                                      setEmpCode(item?.pk_emp_code);
                                    }}
                                  >
                                    <i className="bi bi-trash-fill" />
                                  </button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="no-data-found">
                              <td colSpan={9}>---- No Data Found ----</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="employee-down">
                  <span className="page-range">
                    <p>{pageDataFrom}</p>-<p>{pageDataTill}</p>
                    &nbsp; of &nbsp;
                    <p>{totalemployee}</p>
                  </span>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<i className="fa fa-angle-double-right" />}
                    onPageChange={handlePageClick}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={<i className="fa fa-angle-double-left" />}
                    renderOnZeroPageCount={null}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    disabledClassName={"disabled"}
                  />
                  <button
                    type="button"
                    className="btn btn-outline-primary float-end employee-button"
                    onClick={(e) => exportExcel(employee)}
                  >
                    Download &nbsp;
                    <img src={excelimg} alt="excel" className="thumbnail" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <AccessDenied />
      )}
      <>
        <Modal
          show={alertModelShow}
          onHide={handleAlertClose}
          className="main_model alert-message-model"
        >
          <div className="alert-msg-box-delete">
            <div className="delete-employee-title">
              <span className="model-title-delete d-flex">
                <h6 className="delete-head-text">Delete Employee</h6>
                <i className="bi bi-trash-fill" />
              </span>
              <i className="fa fa-times" onClick={handleAlertClose} />
            </div>
            <div className="alert-status-msg-delete">
              {deleteSuccessRes ? (
                <p className="delete-success-msg"> {deleteSuccessRes} </p>
              ) : (
                <p> Are you sure to delete this Employee? </p>
              )}
            </div>
            <div className="delete-ok-cancel">
              <button
                className="btn btn-ok"
                onClick={() => deleteEmployee(empCode)}
              >
                Delete
              </button>
              <button
                className="btn btn-cancel-model"
                onClick={handleAlertClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </>
    </div>
  );
}

export default Employee;
