import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Topbar from "./Topbar";
import { Link } from "react-router-dom";
import excelimg from "../images/excel.png";
import "../css/branch.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { branchnamecode } from "./Branchnamecode";
import { Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import "../css/pagination.css";
import AccessDenied from "../AccessDenied";
import { requestOptionsGet } from "./requestOptionHeaders";
import { baseURL } from "./BaseUrl";

function Branch() {
  const userType = sessionStorage.getItem("loginType");

  const [branchdata, setBranchdata] = useState([]);
  const [searchselect, setSearchselect] = useState([]);
  const [searchval, setSearchval] = useState("");
  const [branchPodata, setBranchPodata] = useState([]);
  const [branchname, setBranchName] = useState({});
  const [city, setCity] = useState({});
  const [mobilenum, setMobileNum] = useState({});

  const [isMenuOpen, setIsMenuOpen] = useState({
    style: "menu active",
    menuStatus: "open",
  });
  const [pagewrap, setPageWrap] = useState({
    style: "",
  });

  // PAGINATION START
  const PageSize = 20;
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  // console.log("PAGENO", currentPage);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  // DOWNLOAD EXCEL-FORMATE START
  const fileName = "Branch-Details";
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const Heading = [
    {
      pk_branch_name: "Branch Name",
      branch_code: "Branch Code",
      purchase_order: "PO",
      invoice_status: "INV",
      payment_status: "PP",
      warehouse_city: "City",
      warehouse_address: "Location",
      warehouse_state: "Mobile Number",
    },
  ];

  const exportExcel = () => {
    const newBranchData = branchdata.map((data) => {
      delete data.warehouse_country;
      return data;
    });

    const ws = XLSX.utils.json_to_sheet(Heading, {
      header: [
        "pk_branch_name",
        "branch_code",
        "purchase_order",
        "invoice_status",
        "payment_status",
        "warehouse_city",
        "warehouse_address",
        "warehouse_state",
      ],
      skipHeader: true,
      origin: 0,
    });
    XLSX.utils.sheet_add_json(ws, branchdata, {
      header: [
        "pk_branch_name",
        "branch_code",
        "purchase_order",
        "invoice_status",
        "payment_status",
        "warehouse_city",
        "warehouse_address",
        "warehouse_state",
      ],
      skipHeader: true,
      origin: -1,
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  // DOWNLOAD EXCEL-FORMATE END

  useEffect(() => {
    fetchbranchdata();
  }, [currentPage]);

  const fetchbranchdata = () => {
    let requestOption = requestOptionsGet;
    fetch(
      `${baseURL}/branchjson/pages?page_num=${currentPage}&page_size=${PageSize}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log("BRANCHPODATA:", res);
        setBranchdata(res.data);
        setPageDataSize(res.data.length);
        setTotalemployee(res.total);
        setPageCount(Math.ceil(res.total / PageSize));
      })
      .catch((error) => console.error("ERROR:", error));
  };

  const serachBySelect = () => {
    let requestOption = requestOptionsGet;
    fetch(
      `${baseURL}/branchjson/Branch/search_dropdown_filter?pk_branch_name=${branchname}&city=${city}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log("RESPONSE:", res);
        setBranchdata(res);
      })
      .catch((error) => console.error("ERROR:", error));
  };

  const clearSerachBySelect = () => {
    setBranchName("");
    setCity("");
    fetchbranchdata();
  };

  return (
    <div>
      {userType === "Admin" || userType === "admin" ? (
        <div id="form1">
          <div className="leftbar-tab-menu">
            {/* SIDE BAR START */}
            <Sidebar />
            {/* SIDE BAR END */}

            {/* NAV_BAR START */}
            <Navbar isMenuOpen={isMenuOpen.style} />
            {/* NAV_BAR END */}
          </div>

          {/* TOP BAR START */}
          <Topbar
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            pagewrap={pagewrap}
            setPageWrap={setPageWrap}
          />
          {/* TOP BAR END */}

          <div className={`page-wrapper ${pagewrap.style}`}>
            <div className="page-content-tab">
              <div className="container-fluid branch-content">
                <div className="pagecontent">
                  <div className="row branch-summary">
                    {/* SELECT FILTER BY VALUE START */}
                    <span className="page-table-title">BRANCH</span>
                    <span className="seleting-menus">
                      <div className="branch-sea">
                        <div className="input-group mb-1 branch-input">
                          <i className="fa fa-search"></i>
                          <input
                            type="search"
                            placeholder="Branch Name / Mobile Number"
                            aria-describedby="button-addon3"
                            className="form-control bg-none border-0 branch-input1"
                            onChange={(e) => setSearchval(e.target.value)}
                          />
                        </div>
                      </div>
                      <Form.Select
                        aria-label="Default select example"
                        name="branchname"
                        className="form-control select"
                        value={branchname}
                        onChange={(event) => setBranchName(event.target.value)}
                      >
                        <option className="option-label">Branch Name</option>
                        {branchnamecode.map((branch, i) => {
                          return (
                            <option key={i} value={branch.name}>
                              {branch.name}
                            </option>
                          );
                        })}
                      </Form.Select>

                      <Form.Select
                        aria-label="Default select example"
                        name="department"
                        className="form-control select"
                        value={city}
                        onChange={(event) => setCity(event.target.value)}
                      >
                        <option className="option-label">City</option>
                        {branchnamecode.map((branch, i) => {
                          return (
                            <option key={i} value={branch.city}>
                              {branch.city}
                            </option>
                          );
                        })}
                      </Form.Select>

                      <button
                        type="button"
                        className="btn btn-select-search d-flex"
                        onClick={serachBySelect}
                      >
                        <span className="btn-inline-item d-flex">
                          <i className="bi bi-search"> </i>
                          <h6>Search</h6>
                        </span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-clear-select"
                        onClick={clearSerachBySelect}
                      >
                        <i className="bi bi-x-circle-fill"></i>
                      </button>
                    </span>
                    {/* SELECT FILTER BY VALUE END */}
                  </div>

                  <div className="row branch">
                    <div className="table-wrapper-scroll-x my-custom-scrollbar branch-scroll">
                      <table className="table table-responsive branch-table">
                        <thead className="branch-head">
                          <tr>
                            <th className="branch-thead">Branch Code</th>
                            <th className="branch-thead">Branch</th>
                            <th className="branch-thead">PO</th>
                            <th className="branch-thead">INV</th>
                            <th className="branch-thead">PP </th>
                            <th className="branch-thead">City </th>
                            {/* <th className="branch-thead">State </th> */}
                            <th className="branch-thead">Location </th>
                            {/* <th className="branch-thead">Email </th>
                            <th className="branch-thead">Mobile Number </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {branchdata !== "" || branchdata !== null ? (
                            branchdata
                              .filter((brc) =>
                                brc?.pk_branch_name
                                  .toLowerCase()
                                  .includes(searchval)
                              )
                              .map((item, i) => (
                                <tr key={i}>
                                  <td className="branch-tbody">
                                    {item?.branch_code}
                                  </td>
                                  <td className="branch-tbody">
                                    {item?.pk_branch_name}
                                  </td>
                                  <td className="branch-po-tbody">
                                    <Link
                                      to="/purchaseorder"
                                      className="branch-po"
                                    >
                                      {item?.purchase_order}
                                    </Link>
                                  </td>
                                  <td className="branch-po-tbody">
                                    <Link
                                      to="/invoicestatus"
                                      className="branch-inv"
                                    >
                                      {item?.invoice_status}
                                    </Link>
                                  </td>
                                  <td className="branch-po-tbody">
                                    <Link
                                      to="/paymentstatus"
                                      className="branch-pp"
                                    >
                                      {item?.payment_status}
                                    </Link>
                                  </td>
                                  <td
                                    className="branch-tbody"
                                    style={{ maxWidth: "150px" }}
                                  >
                                    {item?.warehouse_city}
                                  </td>
                                  {/* <td className="branch-tbody">{item?.state}</td> */}
                                  <td
                                    className="branch-tbody"
                                    style={{ maxWidth: "200px" }}
                                  >
                                    {item?.warehouse_address}
                                  </td>
                                  {/* <td className="branch-tbody">Email</td>
                                <td className="branch-tbody">Mobiile Num</td> */}
                                </tr>
                              ))
                          ) : (
                            <span className="no-data-found">
                              ---- No Data Found ----
                            </span>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="branch-down d-flex">
                  <span className="page-range">
                    <p>{pageDataFrom}</p>-<p>{pageDataTill}</p>
                    &nbsp; of &nbsp;
                    <p>{totalemployee}</p>
                  </span>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<i className="fa fa-angle-double-right" />}
                    onPageChange={handlePageClick}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={<i className="fa fa-angle-double-left" />}
                    renderOnZeroPageCount={null}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    disabledClassName={"disabled"}
                  />

                  <button
                    type="button"
                    className="btn btn-outline-info float-end down-button"
                    onClick={(e) => exportExcel(branchdata)}
                  >
                    Download &nbsp;&nbsp;
                    <img src={excelimg} alt="pdf" className="thumbnail" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default Branch;
