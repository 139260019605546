import React from "react";
import "../src/css/pagenotfound.css";

function PageNotFound() {
  return (
    <div>
      <div className="page-not-found-page">
        <h2 className="page-not-found-head">404 Page Not Found</h2>
        <p>We are sorry but the page you are looking for does not exist.</p>
      </div>
    </div>
  );
}

export default PageNotFound;
