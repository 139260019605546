import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Topbar from "./Topbar";
import { requestOptionsGet } from "./requestOptionHeaders";
import { baseURL } from "./BaseUrl";
import excelimg from "../images/excel.png";
import "../css/paymentstatus.css";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import "../css/pagination.css";
import ViewPaymentDetails from "./ViewPaymentDetails";
import AccessDenied from "../AccessDenied";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Paymentstatus() {
  const { vendorid } = useParams();

  const [paymentdata, setPaymentdata] = useState([]);
  const [searchval, setSearchval] = useState("");
  const currentDate = new Date();
  const oneMonthAgo = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 30
  );
  const [fromDate, setFromDate] = useState(oneMonthAgo);
  const [toDate, setToDate] = useState(new Date());
  const [status, setStatus] = useState({});
  const [viewdetails, setViewdetails] = useState(true);
  const [viewpaymentdata, setViewpaymentdata] = useState("");
  const [presentDate, setPresentDate] = useState("");

  const [isMenuOpen, setIsMenuOpen] = useState({
    style: "menu active",
    menuStatus: "open",
  });
  const [pagewrap, setPageWrap] = useState({
    style: "",
  });

  // PAGINATION START
  const PageSize = 20;
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  // console.log("PAGENO", currentPage);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  // DOWNLOAD EXCEL-FORMATE START

  const fileName = "Payment Status";
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const Heading = [
    {
      pk_payment_num: "Payment No.",
      fk_branch: "Branch",
      payment_amount: "Amount",
      payment_date: "Payment Date",
      payment_ref: "Payment Reference",
      vendor_name: "Vendor Name",
      narration: "Narration",
      status: "Status",
    },
  ];

  const exportExcel = () => {
    const newInvoiceData = paymentdata.map((data) => {
      return {
        pk_payment_num: data.pk_payment_num,
        fk_branch: data.fk_branch,
        payment_amount: data.payment_amount,
        payment_date: moment(data.payment_date).format("DD/MM/YYYY"),
        payment_ref: data.payment_ref,
        vendor_name: data.vendor_name[0],
        narration: data.narration,
        status: data.status,
      };
    });

    const ws = XLSX.utils.json_to_sheet(Heading, {
      header: [
        "pk_payment_num",
        "fk_branch",
        "payment_amount",
        "payment_date",
        "payment_ref",
        "vendor_name",
        "narration",
        "status",
      ],
      skipHeader: true,
      origin: 0,
    });
    XLSX.utils.sheet_add_json(ws, newInvoiceData, {
      header: [
        "pk_payment_num",
        "fk_branch",
        "payment_amount",
        "payment_date",
        "payment_ref",
        "vendor_name",
        "narration",
        "status",
      ],
      skipHeader: true,
      origin: -1,
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  // DOWNLOAD EXCEL-FORMATE END

  var userType = sessionStorage.getItem("loginType");
  if (userType === "Approver" || userType === "approver") {
    var empId = sessionStorage.getItem("login-code");
  } else if (userType === "vendor" || userType === "Vendor") {
    var vendorCode = sessionStorage.getItem("login-code");
  }

  useEffect(() => {
    fetchpaymentdata();
  }, [currentPage, PageSize, fromDate, toDate, searchval]);

  const fetchpaymentdata = () => {
    let requestOption = requestOptionsGet;
    if (userType === "vendor" || userType === "Vendor") {
      fetch(
        `${baseURL}/payment_statusjson/get_selected_item/payment_status_based_on_vendor_code/?start_date=${moment(
          fromDate
        ).format("YYYY-MM-DD")}&end_date=${moment(toDate).format(
          "YYYY-MM-DD"
        )}&fk_vendor_code=${vendorCode}&search=${searchval}`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          // console.log("PP-VEN-RES:::", res);
          setPaymentdata(res);
        })
        .catch((error) => console.error("ERROR:", error));
    } else if (vendorid) {
      fetch(
        `${baseURL}/payment_statusjson/get_selected_item/payment_status_based_on_vendor_code/?start_date=${moment(
          fromDate
        ).format("YYYY-MM-DD")}&end_date=${moment(toDate).format(
          "YYYY-MM-DD"
        )}&fk_vendor_code=${vendorid}&search=${searchval}`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          // console.log("PP-VEN-RES:::", res);
          setPaymentdata(res);
        })
        .catch((error) => console.error("ERROR:", error));
    } else if (userType === "Approver" || userType === "approver") {
      fetch(
        `${baseURL}/payment_statusjson_matching_owners/from_vendor_table/?start_date=${moment(
          fromDate
        ).format("YYYY-MM-DD")}&end_date=${moment(toDate).format(
          "YYYY-MM-DD"
        )}&emp_code=${empId}&search=${searchval}&page_num=${currentPage}&page_size=${PageSize}`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          // console.log("INV-VEN-RES:::", res);
          setPaymentdata(res.data);
          setPageDataSize(res.data.length);
          setTotalemployee(res.total);
          setPageCount(Math.ceil(res.total / PageSize));
        })
        .catch((error) => console.error("ERROR:", error));
    } else {
      fetch(
        `${baseURL}/payment_statusjson/pages?start_date=${moment(
          fromDate
        ).format("YYYY-MM-DD")}&end_date=${moment(toDate).format(
          "YYYY-MM-DD"
        )}&page_num=${currentPage}&page_size=${PageSize}&search=${searchval}`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          // console.log("PAYMENT-RESPONSE:", res);
          setPaymentdata(res.data);
          setPageDataSize(res.data.length);
          setTotalemployee(res.total);
          setPageCount(Math.ceil(res.total / PageSize));
        })
        .catch((error) => console.error("ERROR:", error));
    }
  };

  const serachBySelect = () => {
    fetchpaymentdata();
  };

  const clearSerachBySelect = () => {
    setFromDate(oneMonthAgo);
    setToDate(new Date());
    setStatus({});
    fetchpaymentdata();
  };

  const viewComponent = (paynum) => {
    setViewdetails(!viewdetails);
    setViewpaymentdata(paynum);
  };

  const goBacktopurchaseorder = () => {
    setViewdetails(!viewdetails);
  };

  return (
    <div>
      {userType === "Admin" ||
      userType === "admin" ||
      userType === "Vendor" ||
      userType === "vendor" ||
      userType === "Approver" ||
      userType === "approver" ? (
        <div id="form1">
          <div className="leftbar-tab-menu">
            {/* SIDE BAR START */}
            <Sidebar />
            {/* SIDE BAR END */}

            {/* NAV_BAR START */}
            <Navbar isMenuOpen={isMenuOpen.style} />
            {/* NAV_BAR END */}
          </div>

          {/* TOP BAR START */}
          <Topbar
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            pagewrap={pagewrap}
            setPageWrap={setPageWrap}
          />
          {/* TOP BAR END */}

          {viewdetails ? (
            <div className={`page-wrapper ${pagewrap.style}`}>
              <div className="page-content-tab">
                <div className="container-fluid pay-cont">
                  <div className="pagecontent">
                    <div className="row payment-summary">
                      <span className="page-table-title">PAYMENT STATUS</span>

                      {/* SELECT FILTER BY VALUE START */}
                      <span className="seleting-menus">
                        <div className="payment-sea">
                          <div className="input-group payment-input">
                            <i className="fa fa-search"></i>
                            <input
                              type="search"
                              placeholder="Payment No."
                              aria-describedby="button-addon3"
                              className="form-control bg-none border-0 paystatus-input"
                              onChange={(e) => setSearchval(e.target.value)}
                            />
                          </div>
                        </div>

                        <span className="input-group from-to-date-picker d-flex">
                          <span
                            className="input-group-text select"
                            id="from-label"
                          >
                            From
                          </span>
                          <div>
                            <DatePicker
                              selected={fromDate}
                              // selectsStart
                              className="select from-date"
                              dateFormat="dd/MM/yyyy"
                              startDate={fromDate}
                              endDate={toDate}
                              onChange={(date) => setFromDate(date)}
                              maxDate={new Date()}
                            />
                          </div>
                          <span
                            className="input-group-text select"
                            id="to-label"
                          >
                            to
                          </span>
                          <div>
                            <DatePicker
                              selected={toDate}
                              // selectsEnd
                              className="select from-date"
                              dateFormat="dd/MM/yyyy"
                              startDate={fromDate}
                              endDate={toDate}
                              minDate={fromDate}
                              onChange={(date) => setToDate(date)}
                              maxDate={new Date()}
                            />
                          </div>
                        </span>

                        <Form.Select
                          aria-label="Default select example"
                          name="branch"
                          className="form-control select"
                          value={status}
                          onChange={(event) => setStatus(event.target.value)}
                        >
                          <option className="option-label" value="">
                            Status
                          </option>
                          <option>Paid</option>
                          <option>Pending</option>
                        </Form.Select>

                        <button
                          type="button"
                          className="btn btn-select-search d-flex"
                          onClick={serachBySelect}
                        >
                          <span className="btn-inline-item d-flex">
                            <i className="bi bi-search"> </i>
                            <h6>Search</h6>
                          </span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-clear-select"
                          onClick={clearSerachBySelect}
                        >
                          <i className="bi bi-x-circle-fill"></i>
                        </button>
                      </span>
                      {/* SELECT FILTER BY VALUE END */}
                    </div>
                    <div className="row paymentsta">
                      <div className="table-wrapper-scroll-x my-custom-scrollbar paymentsta-scroll">
                        <table
                          className="table payment-table"
                          id="payment-status-data"
                        >
                          <thead className="payment-head">
                            <tr>
                              <th className="pay-thead">Payment.No</th>
                              <th className="pay-thead">Date</th>
                              {/* <th className="pay-thead">Vendor Ref.No</th> */}

                              {userType === "vendor" ||
                              userType === "Vendor" ? (
                                ""
                              ) : (
                                <th className="pay-thead">Vendor Name</th>
                              )}
                              {/* <th className="pay-thead">Branch</th> */}
                              <th className="pay-thead">Amount</th>
                              <th className="pay-thead">Naration</th>
                              <th className="pay-thead sta">Status</th>
                              <th className="pay-thead">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(userType !== "vendor" || userType !== "Vendor") &&
                            (paymentdata !== "" || paymentdata !== null) ? (
                              paymentdata
                                .filter((pay) =>
                                  pay?.pk_payment_num
                                    ?.toString()
                                    ?.includes(searchval)
                                )
                                .map((item, i) => (
                                  <tr key={i}>
                                    <td className="pay-tbody">
                                      <span
                                        className="pay-act"
                                        onClick={() =>
                                          viewComponent(item?.pk_payment_num)
                                        }
                                      >
                                        {item?.pk_payment_num}
                                      </span>
                                    </td>
                                    <td className="pay-tbody">
                                      {moment(item?.payment_date).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    {/* <td className="pay-tbody">
                                      {item?.vendor_ref_no}
                                    </td> */}
                                    {userType === "vendor" ||
                                    userType === "Vendor" ? (
                                      ""
                                    ) : (
                                      <td className="pay-tbody">
                                        {item?.vendor_name}
                                      </td>
                                    )}
                                    {/* <td className="pay-tbody">
                                      {item?.fk_branch}
                                    </td> */}
                                    <td className="pay-tbody">
                                      {item?.payment_amount}
                                    </td>
                                    <td
                                      className="pay-tbody"
                                      style={{ maxWidth: "200px" }}
                                    >
                                      {item?.narration}
                                    </td>
                                    <td className="pay-tbody sta">
                                      {item?.status === "paid" ? (
                                        <span className="pay-paid">Paid</span>
                                      ) : (
                                        <span className="pay-pen">Pending</span>
                                      )}
                                    </td>
                                    <td className="pay-tbody">
                                      <button
                                        className="btn btn-viewpayment-details"
                                        onClick={() =>
                                          viewComponent(item?.pk_payment_num)
                                        }
                                      >
                                        <i className="bi bi-eye-fill" /> View
                                      </button>
                                    </td>
                                  </tr>
                                ))
                            ) : (
                              <>
                                {paymentdata !== "" || paymentdata !== null ? (
                                  paymentdata.map((item, i) => (
                                    <tr key={i}>
                                      <td className="pay-tbody">
                                        <span className="pay-act">
                                          {item?.pk_payment_num}
                                        </span>
                                      </td>
                                      <td className="pay-tbody">
                                        {moment(item?.payment_date).format(
                                          "YYYY/MM/DD"
                                        )}
                                      </td>
                                      <td className="pay-tbody">
                                        {item?.payment_ref}
                                      </td>
                                      {userType !== "vendor" ||
                                      userType !== "Vendor" ? (
                                        <td className="pay-tbody">
                                          {item?.vendor_name}
                                        </td>
                                      ) : (
                                        ""
                                      )}
                                      {/* <td className="pay-tbody">
                                        {item.fk_branch}
                                      </td> */}
                                      <td className="pay-tbody">
                                        {item?.payment_amount}
                                      </td>
                                      <td className="pay-tbody">
                                        {item?.narration}
                                      </td>
                                      <td className="pay-tbody sta">
                                        {item?.status === "paid" ? (
                                          <span className="pay-paid">Paid</span>
                                        ) : (
                                          <span className="pay-pen">
                                            Pending
                                          </span>
                                        )}
                                      </td>
                                      <td className="pay-tbody">
                                        <button
                                          className="btn btn-viewpayment-details"
                                          onClick={() =>
                                            viewComponent(item?.pk_payment_num)
                                          }
                                        >
                                          <i className="bi bi-eye-fill" /> View
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <span className="no-data-found">
                                    ---- No Data Found ----
                                  </span>
                                )}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="paystatus-down ">
                    <span className="page-range">
                      <p>{pageDataFrom}</p>-<p>{pageDataTill}</p>
                      &nbsp; of &nbsp;
                      <p>{totalemployee}</p>
                    </span>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={<i className="fa fa-angle-double-right" />}
                      onPageChange={handlePageClick}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      pageCount={pageCount}
                      previousLabel={<i className="fa fa-angle-double-left" />}
                      renderOnZeroPageCount={null}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      disabledClassName={"disabled"}
                    />
                    <button
                      type="button"
                      className="btn btn-outline-primary float-right paystatus-button"
                      onClick={(e) => exportExcel(paymentdata)}
                    >
                      Download &nbsp;
                      <img src={excelimg} alt="excel" className="thumbnail" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <ViewPaymentDetails
              viewdetails={viewdetails}
              goBacktopurchaseorder={goBacktopurchaseorder}
              pagewrap={pagewrap}
              viewpaymentdata={viewpaymentdata}
            />
          )}
        </div>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default Paymentstatus;
