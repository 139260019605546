import React, { useState } from "react";
import userimg from "../images/06.png";
import "../css/navbar.css";
import "../css/dashboard.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function Topbar(props) {
  const [toggleshift, setToggleshift] = useState({ style: "" });

  let navigate = useNavigate();

  const toggleMenu = () => {
    switch (props.isMenuOpen.menuStatus) {
      case "open":
        props.setIsMenuOpen({
          menuStatus: "close",
          style: "menu active",
        });
        props.setPageWrap({
          style: "",
        });
        setToggleshift({ style: "" });
        break;
      case "close":
        props.setIsMenuOpen({
          menuStatus: "open",
          style: "menu",
        });
        props.setPageWrap({
          style: "expand",
        });
        setToggleshift({ style: "toggle-shift-left" });
        break;
      default:
        break;
    }
  };

  const userName = sessionStorage.getItem("login-name");
  const userType = sessionStorage.getItem("loginType");

  const logOut = () => {
    sessionStorage.clear();
    navigate("/");
    window.location.reload();
  };

  return (
    <div>
      <div className="topbar">
        <nav className="navbar-custom" id="navbar-custom">
          <ul className="list-unstyled topbar-nav float-end mb-0 navbar-content-img">
            <div className="logged-in-by">
              <h6 className="login-user-name-email">{userName}</h6>
              <span className="login-tyspane">{userType}</span>
            </div>
            <li className="dropdown notification-list">
              {/* <i className="fa fa-bell my-bell" aria-hidden="true"></i> */}
              &nbsp;&nbsp;
              <img
                src={userimg}
                alt="msd"
                className="rounded-circle pro dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              />
              <ul
                className="dropdown-menu user-profile-logout"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <button
                    className="dropdown-item log-out-btn"
                    onClick={logOut}
                  >
                    <h6>Logout</h6>
                    <i className="bi bi-box-arrow-right"></i>
                  </button>
                </li>
              </ul>
            </li>
          </ul>

          <div className="list-unstyled topbar-nav mb-0">
            <li>
              <button
                className={`nav-link button-menu-mobile nav-icon ${toggleshift.style}`}
                id="togglemenu"
                onClick={toggleMenu}
              >
                <i className="fa fa-bars" aria-hidden="true"></i>
              </button>
            </li>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Topbar;
