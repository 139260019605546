import React from "react";
import { NavLink, Link, useParams } from "react-router-dom";
// import "../css/navbar.css";

function Navbar(props) {
  let style = props.isMenuOpen;
  // const { metricaDashboard } = useParams();
  // console.log("MetricaDashboard:", props.metricaDashboard);
  var userType = sessionStorage.getItem("loginType");

  return (
    <div>
      <div className={`main-menu-inner ${style}`}>
        <div className="topbar-left"></div>
        <div className="menu-body navbar-vertical tab-content" data-simplebar>
          {/* {props.metricaDashboard ? ( */}
          <div
            id="MetricaDashboard"
            className="main-icon-menu-pane tab-pane"
            role="tabpanel"
            aria-labelledby="dasboard-tab"
          >
            <ul className="navbar-nav flex-column">
              <li className="nav-item">
                <NavLink className="nav-link" to="/dashboard">
                  <i
                    className="bi bi-columns-gap"
                    style={{ marginRight: "15px" }}
                  ></i>
                  DASHBOARD
                </NavLink>
              </li>
              <li
                className="nav-item"
                style={{
                  display:
                    userType === "Vendor" ||
                    userType === "vendor" ||
                    userType === "FM" ||
                    userType === "Approver"
                      ? "none"
                      : "block",
                }}
              >
                <NavLink className="nav-link emp-link" to="/employee">
                  EMPLOYEE MASTER
                </NavLink>
              </li>
              <li
                className="nav-item"
                style={{
                  display:
                    userType === "Vendor" ||
                    userType === "vendor" ||
                    userType === "Approver" ||
                    userType === "FM"
                      ? "none"
                      : "block",
                }}
              >
                <NavLink className="nav-link branch-link" to="/branch">
                  BRANCH
                </NavLink>
              </li>
              <li
                className="nav-item"
                style={{
                  display:
                    userType === "vendor" ||
                    userType === "Vendor" ||
                    userType === "Approver" ||
                    userType === "FM"
                      ? "none"
                      : "block",
                }}
              >
                <NavLink className="nav-link vendor-link" to="/vendor">
                  VENDORS
                </NavLink>
              </li>
              <li
                className="nav-item"
                style={{
                  display:
                    userType === "FM" || userType === "Approver"
                      ? "none"
                      : "block",
                }}
              >
                <NavLink className="nav-link purchase-link" to="/purchaseorder">
                  PURCHASE ORDERS
                </NavLink>
              </li>
              <li
                className="nav-item"
                style={{
                  display:
                    userType === "Admin" || userType === "vendor"
                      ? "none"
                      : "block",
                }}
              >
                <NavLink className="nav-link invo-link" to="/approvalmatrix">
                  APPROVAL MATRIX
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link invo-link" to="/invoicestatus">
                  INVOICE STATUS
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link payment-link" to="/paymentstatus">
                  PAYMENT STATUS
                </NavLink>
              </li>
            </ul>
          </div>
          {/* ) : (
            ""
          )} */}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
